import { Box, Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';

const PlayerRatings = ({ player }) => {
  const getColor = (value) => {
    if (value >= 60) return '#76FF03'; // Green for high scores
    if (value >= 50) return '#FFAB00'; // Yellow for medium scores
    return '#F44336'; // Red for low scores
  };
  return (
    <Card style={{ backgroundColor: '#1A237E', padding: 8, borderRadius: 6 }}>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        {/* Doubles Section */}
        <Grid item xs={6} style={{ position: 'relative' }}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ backgroundColor: '#3949AB', borderRadius: 6, padding: 6 }}>
            <Typography variant="body1" style={{ color: 'white', marginBottom: 8 }}>
              <span role="img" aria-label="doubles">
                👥
              </span>{' '}
              Doubles
            </Typography>
            <Typography variant="h4" style={{ color: 'white', fontWeight: 'bold' }}>
              {player?.ratings?.doubles || '-'}
            </Typography>
            <Box position="absolute" bottom={10} right={10} display="inline-flex">
              <CircularProgress variant="determinate" value={player?.ratings?.doublesReliabilityScore} size={40} style={{ color: getColor(player?.ratings?.doublesReliabilityScore) }} />
              <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="font20" style={{ color: '#76FF03', position: 'absolute', top: 8 }}>
                  {player?.ratings?.doublesReliabilityScore}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Singles Section */}
        <Grid item xs={6} style={{ position: 'relative' }}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ backgroundColor: '#283593', borderRadius: 6, padding: 6 }}>
            <Typography variant="body1" style={{ color: 'white', marginBottom: 8 }}>
              <span role="img" aria-label="singles">
                👤
              </span>
              Singles
            </Typography>
            <Typography variant="h4" style={{ color: 'white', fontWeight: 'bold' }}>
              {player?.ratings?.singles || '-'}
            </Typography>
            <Box position="absolute" display="inline-flex" bottom={10} right={10}>
              <CircularProgress variant="determinate" value={player?.ratings?.singlesReliabilityScore} size={40} style={{ color: getColor(player?.ratings?.singlesReliabilityScore) }} />
              <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="font20" style={{ color: '#FFAB00', position: 'absolute', top: 8 }}>
                  {player?.ratings?.singlesReliabilityScore}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
export default PlayerRatings;
