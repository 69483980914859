import { Box, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment } from 'react';
import { SeedItem, SeedTeam } from 'react-brackets';
import { formatSeed, parseScores, processLongName } from 'src/constants';
import GetFlag from 'src/utils/getFlags';
import { findTeamByKey, getShortMatchStatus } from 'src/utils/helperFunction';
import ScoreDisplay from './ScoreComp';
import TeamComponent from 'src/components/shared/TeamComponent';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  seedTeam: {
    borderTop: '1px solid #EDF1F6',
    height: '40px',
    paddingRight: '0.7rem !important',
  },
  seedAddTeam: {
    borderRadius: '4px 4px 0px 0px',
    borderTop: '1px solid #EFEFEF',
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0.8rem 0.7rem !important',
    color: '#193660',
    cursor: 'pointer',
    '@media print': { display: 'none !important' },
  },
  seedTeams: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '320px',
    maxWidth: '100%',
    minWidth: '320px',
    borderRadius: '4px 4px 0px 0px',
    borderTop: '1px solid #EDF1F6',
    borderLeft: '1px solid #EDF1F6',
    borderRight: '1px solid #EDF1F6',
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0.6rem 0.7rem !important',
    color: '#193660',
    background: '#ffff',
    fontSize: '11px',
  },
  winnerTeamTitle: {
    color: '#19366066',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    display: 'flex',
    gap: '0',
    justifyContent: 'center',
    alignItems: 'center',
    '@media print': { display: 'none !important' },
  },
  loserTeamTitle: {
    color: 'rgba(25, 54, 96, 0.40)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    display: 'flex',
    gap: '10px',
    justifyContent: 'start',
    textAlign: 'start',
  },
  title: {
    color: '#193660',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    padding: '10px',
    textAlign: 'start',
  },
}));

const ThirdPlaceMatch = ({ thirdPlaceRound, draw, isTeam, drawCategory }) => {
  const seed = thirdPlaceRound.seeds?.length > 0 && thirdPlaceRound.seeds[0];
  const teamOne = findTeamByKey('teamOne', seed?.teams);
  const teamTwo = findTeamByKey('teamTwo', seed?.teams);

  const addTeam1 = findTeamByKey('addTeam1', seed?.teams);
  const addTeam2 = findTeamByKey('addTeam2', seed?.teams);

  const classes = useStyles();

  return (
    <Fragment>
      <SeedItem
        style={{
          maxWidth: '320px',
          width: '100%',
          background: 'transparent',
          float: 'right',
          marginBottom: '15px',
          marginRight: '18px',
          boxShadow: 'none',
        }}
      >
        <Typography className={classes.title}>3rd place</Typography>
        <Box sx={{ position: 'relative', border: '1px solid #efefef', borderRadius: '8px' }}>
          {isTeam && !isEmpty(teamOne?.team) ? (
            <SeedTeam className={classes.seedTeams}>
              <TeamComponent
                team={teamOne?.team}
                users={teamOne?.users}
                maxWidth="180px"
                highlightWinner={seed?.winner?.id === seed?.teams[0]?.id}
                highlightColor={process.env.REACT_APP_PRIMARY_COLOR || '#FF5733'}
                showTeamMembers={drawCategory?.category?.identifyTeamMember}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {seed?.winner?.id === seed?.teams[0]?.id ? (
                  <>
                    <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.firstArray} scores2={parseScores(seed?.addScore?.addScore)?.secondArray} winner={true} />
                    {seed?.scoreType && <Box sx={{ minWidth: '20px' }}></Box>}
                  </>
                ) : (
                  <>
                    <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.secondArray} scores2={parseScores(seed?.addScore?.addScore)?.firstArray} winner={false} />
                    {seed?.scoreType && <Box sx={{ minWidth: '20px' }}>{seed?.scoreType || ''}</Box>}
                  </>
                )}
              </Box>
            </SeedTeam>
          ) : (
            <>
              {' '}
              {teamOne && (
                <SeedTeam className={`${classes.seedTeams}`}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      {/* {!!(teamOne?.teamOne[0]?.rank ?? teamOne?.teamOne[0]?.rank) && (
                    <Typography
                      sx={{
                        width: '24px',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: 500,
                        lineHeight: '100%',
                      }}
                    >
                      {teamOne?.teamOne[0]?.rank ?? teamOne?.teamOne[0]?.rank}
                    </Typography>
                  )} */}
                      <GetFlag
                        country={teamOne?.teamOne[0]?.nation}
                        style={{
                          width: '15px',
                          height: '10px',
                          marginRight: '5px',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: seed?.winner?.id === seed?.teams[0]?.id ? 600 : 500,
                          color: seed?.winner?.id === seed?.teams[0]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                        }}
                      >
                        {processLongName(teamOne?.teamOne[0]?.player1)} {formatSeed(seed?.teams[0], draw?.segment)}
                      </Typography>
                    </Box>
                    {draw?.isDisplayParticipantsClub && teamOne?.teamOne?.[0]?.club?.clubName && (
                      <Typography sx={{ marginLeft: '25px', fontSize: '10px', textAlign: 'left', display: 'flex', alignItems: 'center', gap: 0.8 }}>
                        <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                        {teamOne?.teamOne?.[0]?.club?.clubName}
                      </Typography>
                    )}
                    {teamOne?.teamOne?.length > 1 && (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '5px' }}>
                          {/* {teamOne?.teamOne[1]?.rank && ( */}
                          {/* {!!(teamOne?.teamOne[1]?.rank ?? teamOne?.teamOne[1]?.rank) && (
                        <Typography
                          sx={{
                            width: '24px',
                            fontFamily: 'Inter',
                            fontSize: '11px',
                            fontWeight: 500,
                            lineHeight: 'normal',
                          }}
                        >
                          {teamOne?.teamOne[1]?.rank ?? teamOne?.teamOne[1]?.rank}
                        </Typography>
                      )} */}
                          {/* )} */}
                          <GetFlag
                            country={teamOne.teamOne[1].nation}
                            style={{
                              width: '15px',
                              height: '10px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              // pl: '5px',
                              fontSize: '12px',
                              fontWeight: seed?.winner?.id === seed?.teams[0]?.id ? 600 : 500,
                              color: seed?.winner?.id === seed?.teams[0]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                            }}
                          >
                            {processLongName(teamOne?.teamOne[1]?.player2)} {formatSeed(seed?.teams[0], draw?.segment)}
                          </Typography>
                        </Box>
                        {draw?.isDisplayParticipantsClub && teamOne?.teamOne?.[1]?.club?.clubName && (
                          <Typography sx={{ marginLeft: '25px', fontSize: '10px', textAlign: 'left', display: 'flex', alignItems: 'center', gap: 0.8 }}>
                            <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                            {teamOne?.teamOne?.[1]?.club?.clubName}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {seed?.winner?.id === seed?.teams[0]?.id ? (
                      <>
                        <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.firstArray} scores2={parseScores(seed?.addScore?.addScore)?.secondArray} winner={true} />
                        {seed?.scoreType && <Box sx={{ minWidth: '20px' }}></Box>}
                      </>
                    ) : (
                      <>
                        <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.secondArray} scores2={parseScores(seed?.addScore?.addScore)?.firstArray} winner={false} />
                        {seed?.scoreType && <Box sx={{ minWidth: '20px' }}>{seed?.scoreType || ''}</Box>}
                      </>
                    )}
                  </Box>
                </SeedTeam>
              )}
            </>
          )}
          {addTeam1 && <SeedTeam style={{ minHeight: '40px', color: process.env.REACT_APP_PRIMARY_COLOR || '#86909F', fontSize: '12px', marginLeft: '8px' }}>X</SeedTeam>}
          {addTeam2 && <SeedTeam style={{ minHeight: '40px', color: process.env.REACT_APP_PRIMARY_COLOR || '#86909F', fontSize: '12px', marginLeft: '8px' }}>X</SeedTeam>}
          {isTeam && !isEmpty(teamTwo?.team) ? (
            <SeedTeam className={`${classes.seedTeams}`} style={{ minHeight: '40px' }}>
              <TeamComponent
                team={teamTwo?.team}
                users={teamTwo?.users}
                maxWidth="180px"
                highlightWinner={seed?.winner?.id === seed?.teams[1]?.id}
                highlightColor={process.env.REACT_APP_PRIMARY_COLOR || '#FF5733'}
                showTeamMembers={drawCategory?.category?.identifyTeamMember}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {seed?.winner?.id === seed?.teams[1]?.id ? (
                  <>
                    <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.firstArray} scores2={parseScores(seed?.addScore?.addScore)?.secondArray} winner={false} />
                    {seed?.scoreType && <Box sx={{ minWidth: '20px' }}></Box>}
                  </>
                ) : (
                  <>
                    <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.secondArray} scores2={parseScores(seed?.addScore?.addScore)?.firstArray} winner={true} />
                    {seed?.scoreType && <Box sx={{ minWidth: '20px' }}>{seed?.scoreType || ''}</Box>}
                  </>
                )}
              </Box>
            </SeedTeam>
          ) : (
            <>
              {teamTwo && (
                <SeedTeam className={`${classes.seedTeams}`}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* {teamTwo?.teamTwo[0]?.rank && ( */}
                      {/* {!!(teamTwo?.teamTwo[0]?.rank ?? teamTwo?.teamTwo[0]?.rank) && (
                    <Typography
                      sx={{
                        width: '24px',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: 500,
                        lineHeight: 'normal',
                      }}
                    >
                      {teamTwo?.teamTwo[0]?.rank ?? teamTwo?.teamTwo[0]?.rank}
                    </Typography>
                  )}{' '} */}
                      <GetFlag
                        country={teamTwo.teamTwo[0].nation}
                        style={{
                          width: '15px',
                          height: '10px',
                          marginRight: '5px',
                        }}
                      />

                      <Typography
                        sx={{
                          // pl: '5px',
                          fontSize: '12px',
                          color: seed?.winner?.id === seed?.teams[1]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                          fontWeight: seed?.winner?.id === seed?.teams[1]?.id ? 600 : 500,
                        }}
                      >
                        {processLongName(teamTwo.teamTwo[0].player1)} {formatSeed(seed?.teams[1], draw?.segment)}
                      </Typography>
                    </Box>
                    {draw?.isDisplayParticipantsClub && teamTwo?.teamTwo?.[0]?.club?.clubName && (
                      <Typography sx={{ marginLeft: '25px', fontSize: '10px', textAlign: 'left', display: 'flex', alignItems: 'center', gap: 0.8 }}>
                        <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                        {teamTwo?.teamTwo?.[0]?.club?.clubName}
                      </Typography>
                    )}
                    {teamTwo?.teamTwo?.length > 1 && (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '5px' }}>
                          {/* {teamTwo?.teamTwo[1]?.rank && ( */}
                          {/* {!!(teamTwo?.teamTwo[1]?.rank ?? teamTwo?.teamTwo[1]?.rank) && (
                        <Typography
                          sx={{
                            width: '24px',
                            fontFamily: 'Inter',
                            fontSize: '11px',
                            fontWeight: 500,
                            lineHeight: 'normal',
                          }}
                        >
                          {teamTwo?.teamTwo[1]?.rank ?? teamTwo?.teamTwo[1]?.rank}
                        </Typography>
                      )} */}
                          {/* )} */}
                          <GetFlag
                            country={teamTwo.teamTwo[1].nation}
                            style={{
                              width: '15px',
                              height: '10px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              // pl: '5px',
                              fontSize: '12px',
                              color: seed?.winner?.id === seed?.teams[1]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                              fontWeight: seed?.winner?.id === seed?.teams[1]?.id ? 600 : 500,
                            }}
                          >
                            {processLongName(teamTwo?.teamTwo[1].player2)} {formatSeed(seed?.teams[1], draw?.segment)}
                          </Typography>
                        </Box>
                        {draw?.isDisplayParticipantsClub && teamTwo?.teamTwo?.[1]?.club?.clubName && (
                          <Typography sx={{ marginLeft: '25px', fontSize: '10px', textAlign: 'left', display: 'flex', alignItems: 'center', gap: 0.8 }}>
                            <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                            {teamTwo?.teamTwo?.[1]?.club?.clubName}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {seed?.winner?.id === seed?.teams[1]?.id ? (
                      <>
                        <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.firstArray} scores2={parseScores(seed?.addScore?.addScore)?.secondArray} winner={false} />
                        {seed?.scoreType && <Box sx={{ minWidth: '20px' }}></Box>}
                      </>
                    ) : (
                      <>
                        <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.secondArray} scores2={parseScores(seed?.addScore?.addScore)?.firstArray} winner={true} />
                        {seed?.scoreType && <Box sx={{ minWidth: '20px' }}>{seed?.scoreType || ''}</Box>}
                      </>
                    )}
                  </Box>
                </SeedTeam>
              )}
            </>
          )}
        </Box>
        {/* {seed?.addScore && (
          <SeedTeam className={`${classes.seedTeams}`}>
            <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>{`${seed?.addScore?.addScore ?? ''} ${seed?.scoreType ?? ''}`}</Typography>
          </SeedTeam>
        )} */}
        <SeedTeam className={`${classes.seedTeam}`}>
          <Typography className={classes.winnerTeamTitle}>
            {!teamOne && !seed?.court
              ? 'TBA'
              : `${seed?.date ? moment?.utc(seed?.date).format('DD/MM') : ''} ${getShortMatchStatus(seed?.matchStatus, '~', seed?.time) ?? ''}  ${seed?.court ? seed?.court?.name : ''}`}
          </Typography>
        </SeedTeam>
      </SeedItem>
    </Fragment>
  );
};

export default ThirdPlaceMatch;
