import * as Yup from 'yup';

import { Box, Divider, Grid, Modal, Paper, Typography, makeStyles } from '@material-ui/core';
import { Dispatch, FC, Fragment, SetStateAction, useState } from 'react';
import GetFlag from 'src/utils/getFlags';
import CloseIcon from '@material-ui/icons/Close';
import Pill from 'src/components/Pill';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { formatSeed } from 'src/constants';
import { getShortMatchStatus } from 'src/utils/helperFunction';
import TeamComponent from 'src/components/shared/TeamComponent';

interface ScoreItem {
  score: number;
  tie: boolean;
  pair: number;
}

const useStyles = makeStyles((theme) => ({
  removeScoreBox: {
    background: theme.palette.primary.main,
    color: '#FFF',
    // padding: "3px",
    justifyContent: 'center',
    alignItems: 'center',
    height: '14px',
    width: '14px',
    position: 'absolute',
    top: '0px',
    right: '0px',
    display: 'none',
    cursor: 'pointer',
    borderTopRightRadius: '6px',
  },
  toggleButton: {
    height: '70px',
    borderRadius: '8px !important',
    padding: '14px 16px 14px 16px',
    border: '1px solid rgba(239, 239, 240, 1) !important',
    width: '100%',
    overflow: 'hidden',
    '& .MuiToggleButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '16px',
    },
  },
  avatar: {
    width: '45px',
    height: '45px',
    mr: 1,
    border: '3px solid #eee',
  },
  box: {
    overflow: 'hidden',
    textWrap: 'nowrap',
  },
  toggleButtonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    '& .Mui-selected, .MuiButtonBase-root:hover': {
      background: 'linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)',
      color: '#FFF !important',
    },
    '& .Mui-selected .MuiTypography-root': {
      color: '#FFF !important',
    },
    '& .MuiButtonBase-root:hover .MuiTypography-root': {
      color: '#FFF !important',
    },
    '@media (max-width: 560px)': {
      flexWrap: 'wrap',
    },
  },
  scoreBox: {
    borderRadius: '8px',
    border: '1px solid #EDF1F6',
    background: '#FAFAFA',
    display: 'flex',
    padding: '8px',
    alignItems: 'flex-start',
    gap: '8px',
  },
  scoreBoxItems: {
    display: 'flex',
    gap: '8px',
    borderRadius: '6px',
    padding: '6px',
    background: '#F5F5F5',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scoreBoxItem: {
    position: 'relative',
    display: 'flex',
    padding: '10px',
    borderRadius: '6px',
    background: '#FFF',
    alignItems: 'center',
    '&:hover $removeScoreBox': {
      display: 'flex !important',
    },
  },
  scoreBoxItemTie: {
    background: 'linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)',
    color: '#FFF !important',
    marginLeft: '5px',
    '& .MuiOutlinedInput-input': {
      color: '#FFF !important',
    },
  },
  addScoreBoxItems: {
    marginLeft: '10px',
    display: 'flex',
    gap: '8px',
    borderRadius: '6px',
    padding: '6px',
    background: '#F5F5F5',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addScoreBoxItemTie: {
    display: 'flex',
    padding: '10px',
    borderRadius: '6px',
    background: 'linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)',
    color: '#FFF !important',
    marginLeft: '5px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addScoreBoxItem: {
    display: 'flex',
    padding: '10px',
    borderRadius: '6px',
    background: '#FFF',
    alignItems: 'center',
    cursor: 'pointer',
  },
  scoreBoxInput: {
    width: '16px',
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      borderRadius: '0px',
      padding: '0',
      background: 'transparent',
    },
    '& fieldset': {
      border: 'none !important',
    },
  },
  label: {
    fontWeight: 500,
    marginTop: 1,
    marginBottom: '8px',
    color: '#0A2540',
  },
  date: {
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
    background: '#FBFBFB',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
  },
}));

interface ModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  match?: any;
}

const MatchDetailsModal: FC<ModalProps> = ({ openModal, setOpenModal, match }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpenModal(false);
  };

  const getFormatedScore = (inputScore, match) => {
    const scoresByRound =
      Array.isArray(inputScore) &&
      inputScore?.reduce((acc, score) => {
        const round = score.round;
        if (!acc[round]) {
          acc[round] = [];
        }
        acc[round].push(score);
        return acc;
      }, {});

    let formattedScoresString = '';
    const formattedScores = [];
    if (typeof scoresByRound === 'object' && scoresByRound !== null) {
      Object?.keys(scoresByRound)?.forEach((round) => {
        const scoresForRound = scoresByRound[round];

        const entry1 = scoresForRound[0];
        const entry2 = scoresForRound[1];

        if (entry1 && entry2) {
          if (entry1.entry.id === match?.entry1?.id && entry2?.entry?.id === match?.entry2?.id) {
            entry1.roundFormat === 'tie'
              ? formattedScores.push(`[${entry1.score}:${entry2.score}]`)
              : entry1.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry1.score}:${entry2.score})`)
              : formattedScores.push(`${entry1.score}:${entry2.score}`);
          } else {
            entry2.roundFormat === 'tie'
              ? formattedScores.push(`[${entry2.score}:${entry1.score}]`)
              : entry2.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry2.score}:${entry1.score})`)
              : formattedScores.push(`${entry2.score}:${entry1.score}`);
          }
        }
        // formattedScoresString += formattedScores.join(" ") + " ";
      });
    }

    // Remove the trailing space if needed
    // formattedScoresString = formattedScoresString.trim();

    return formattedScores;
  };

  const matchStatus = getShortMatchStatus(match?.matchStatus, '', match?.time, true);

  const classes = useStyles();

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '612px',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 10,
            outline: 'none',
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: '100%',
              maxHeight: '90vh',
              background: '#FFF',
              p: 0.5,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#aaa',
                borderRadius: '6px',
                pl: 3,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#a2a2a2',
              },
            }}
          >
            <Box
              sx={{
                padding: '20px 20px 0px 20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="textPrimary" variant="h2">
                Match Details
              </Typography>
              <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
            </Box>

            <Box sx={{ padding: '20px 20px 0px 20px' }}>
              <Divider />
            </Box>
            <Box sx={{ padding: '20px 20px 20px 20px' }}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
              >
                <Grid item xs={12} sm={5}>
                  {match?.entry1?.team?.title ? (
                    <TeamComponent users={match?.entry1?.users} team={match?.entry1?.team} />
                  ) : (
                    <>
                      {match?.entry1?.users?.map((user, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: '5px',
                            justifyContent: { sm: 'start', xs: 'center' },
                          }}
                          key={user.id}
                        >
                          <GetFlag
                            country={user?.user?.nation}
                            style={{
                              width: '1.2em',
                              height: '1.2em',
                            }}
                          />
                          <Typography
                            sx={{ pl: '5px', marginTop: '-4px', fontWeight: match?.winner?.id === match?.entry1?.id ? 'bold' : 'normal', cursor: 'pointer', textTransform: 'uppercase' }}
                            onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                          >
                            {user?.user?.name + ' ' + user?.user?.surname}
                            {(match?.entry1?.seed || match?.entry1?.assignedSegment) &&
                              index === 0 &&
                              formatSeed(
                                {
                                  seed: match?.entry1?.seed,
                                  assignedSegment: match?.entry1?.assignedSegment,
                                  additionalSegment: match?.entry1?.additionalSegment,
                                },
                                match?.group?.segment,
                              )}
                          </Typography>
                        </Box>
                      ))}
                      {match?.entry1partner?.registrationRequest?.users?.map((user, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: '5px',
                            justifyContent: { sm: 'start', xs: 'center' },
                          }}
                          key={user.id}
                        >
                          <GetFlag
                            country={user?.user?.nation}
                            style={{
                              width: '1.2em',
                              height: '1.2em',
                            }}
                          />
                          <Typography
                            sx={{ pl: '5px', marginTop: '-4px', fontWeight: match?.winner?.id === match?.entry1?.id ? 'bold' : 'normal', cursor: 'pointer', textTransform: 'uppercase' }}
                            onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                          >
                            {user?.user?.name + ' ' + user?.user?.surname}
                            {(match?.entry1?.seed || match?.entry1?.assignedSegment) &&
                              index === 0 &&
                              formatSeed(
                                {
                                  seed: match?.entry1?.seed,
                                  assignedSegment: match?.entry1?.assignedSegment,
                                  additionalSegment: match?.entry1?.additionalSegment,
                                },
                                match?.group?.segment,
                              )}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={2} justifyContent="center">
                  <Typography color="textSecondary" sx={{ textAlign: 'center' }}>
                    VS
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {match?.entry2?.team?.title ? (
                    <TeamComponent users={match?.entry2?.users} team={match?.entry2?.team} />
                  ) : (
                    <>
                      {match?.entry2?.users?.map((user, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: '5px',
                            justifyContent: { sm: 'flex-end', xs: 'center' },
                          }}
                          key={user.id}
                        >
                          <GetFlag
                            country={user?.user?.nation}
                            style={{
                              width: '1.2em',
                              height: '1.2em',
                            }}
                          />
                          <Typography
                            sx={{ pl: '5px', marginTop: '-4px', fontWeight: match?.winner?.id === match?.entry2?.id ? 'bold' : 'normal', cursor: 'pointer', textTransform: 'uppercase' }}
                            onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                          >
                            {user?.user?.name + ' ' + user?.user?.surname}
                            {(match?.entry2?.seed || match?.entry2?.assignedSegment) &&
                              index === 0 &&
                              formatSeed(
                                {
                                  seed: match?.entry2?.seed,
                                  assignedSegment: match?.entry2?.assignedSegment,
                                  additionalSegment: match?.entry2?.additionalSegment,
                                },
                                match?.group?.segment,
                              )}
                          </Typography>
                        </Box>
                      ))}
                      {match?.entry2partner?.registrationRequest?.users?.map((user, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: '5px',
                            justifyContent: { sm: 'flex-end', xs: 'center' },
                          }}
                          key={user.id}
                        >
                          <GetFlag
                            country={user?.user?.nation}
                            style={{
                              width: '1.2em',
                              height: '1.2em',
                            }}
                          />
                          <Typography
                            sx={{ pl: '5px', marginTop: '-4px', fontWeight: match?.winner?.id === match?.entry2?.id ? 'bold' : 'normal', cursor: 'pointer', textTransform: 'uppercase' }}
                            onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                          >
                            {user?.user?.name + ' ' + user?.user?.surname}
                            {(match?.entry2?.seed || match?.entry2?.assignedSegment) &&
                              index === 0 &&
                              formatSeed(
                                {
                                  seed: match?.entry2?.seed,
                                  assignedSegment: match?.entry2?.assignedSegment,
                                  additionalSegment: match?.entry2?.additionalSegment,
                                },
                                match?.group?.segment,
                              )}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  minHeight: '40px',
                  padding: '7px 12px',
                }}
              >
                {typeof match?.score === 'string'
                  ? `${match?.score ?? ''} ${match?.scoreType ?? ''}`
                  : getFormatedScore(match?.score, match)?.map((text) => <Pill text={`${text ?? ''} ${match?.scoreType ?? ''}`} isCourt={true} />)}
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                <Typography color="textSecondary" variant="body1">
                  Date: {moment(match?.date).format('DD/MM/YYYY')}
                </Typography>
                {matchStatus ? (
                  <Typography color="textSecondary" variant="body1">
                    {matchStatus}
                  </Typography>
                ) : (
                  <Typography color="textSecondary" variant="body1">
                    Time: {match?.time}
                  </Typography>
                )}
                <Typography color="textSecondary" variant="body1">
                  Court: {match?.court?.name || 'Unknown'}
                </Typography>
              </Box>
            </Box>
          </Paper>
          {/* <Typography color="textSecondary" variant="body1">
            Winner: {match?.winner?.name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Score: {match?.score}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Date: {match?.date}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Time: {match?.time}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Court: {match?.court}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            Match Status: {match?.matchStatus}
          </Typography> */}
        </Box>
      </Modal>
    </Fragment>
  );
};

export default MatchDetailsModal;
