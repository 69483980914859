import { gql } from '@apollo/client';
const PARTICIPENTS = gql`
  query participents($filter: ListTournamentRegistrationInput, $keyword: String) {
    tournamentRegistrations(filter: $filter, keyword: $keyword) {
      id
      type
      status
      rankType
      assignedSegment
      additionalSegment
      seed
      team {
        title
        country
        image
      }
      categoryId {
        mdwc
        qwc
        qs
        id
        category {
          identifyTeamMember
        }
      }
      users {
        id
        fideTitle
        rank
        blitzElo
        standardElo
        rapidElo
        duprRating
        user {
          rank
          id
          name
          surname
          avatar
          nation
          citizenship
          city
          user_club_id {
            clubName
            city
          }
        }
      }
    }
  }
`;
export default PARTICIPENTS;
