import React, { ReactElement, ReactNode } from 'react';
import { Grid, Typography, Stack, Link, Box } from '@material-ui/core';
import { DownloadOutlined, EventAvailable, EventBusy, Gavel, LocationOn, MonetizationOn, PermContactCalendarOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import SportIcon from '../../../../CustomComponents/SportIcon';
import { getFeeRange } from '../../../../../utils/getFeeRange';
import CustomPaperCard from '../../../../CustomComponents/CustomPaperCard';
import scrollToAndHighlight from '../../../../../utils/useScrollToAndHighlight';
import CustomButton from '../../../../CustomComponents/CustomButton';
import TablerIcon from '../../../../CustomComponents/TablerIcon';
import { useTournament } from '../../TournamentPage';

type Tab = {
  title: ReactNode;
  value: ReactNode;
  icon: ReactElement;
  scrollTo?: string;
};

const Format = () => {
  const { t } = useTranslation();
  const { tournament, loading } = useTournament();

  const formatTabs: Tab[] = [
    ...(tournament?.league?.league?.sports?.title
      ? [
          {
            title: t(tournament?.league.league.sports.title),
            value: t('Sport'),
            icon: <SportIcon color="currentcolor" sport={tournament?.league.league.sports.title} />,
          },
        ]
      : []),
    ...(tournament?.country
      ? [
          {
            title: (
              <>
                {tournament?.country ?? ''}
                {tournament?.city ? ', ' + tournament?.city : ''}
              </>
            ),
            value: tournament?.address,
            icon: <LocationOn sx={{ fontSize: '20px' }} />,
            scrollTo: `#tournament-${tournament?.id}-map`,
          },
        ]
      : []),
    ...(tournament?.startDate && tournament?.timeZone
      ? [
          {
            value: t('Start date'),
            title: (
              <>
                {tournament?.startDate.format('DD.MM, HH:mm')} {tournament?.timeZone.abbreviation}
              </>
            ),
            icon: <EventAvailable sx={{ fontSize: '20px' }} />,
          },
        ]
      : []),
    ...(tournament?.endDate && tournament?.timeZone
      ? [
          {
            value: t('End date'),
            title: (
              <>
                {tournament?.endDate.format('DD.MM, HH:mm')} {tournament?.timeZone.abbreviation}
              </>
            ),
            icon: <EventBusy sx={{ fontSize: '20px' }} />,
          },
        ]
      : []),
    ...(tournament?.entryDeadline && tournament?.timeZone
      ? [
          {
            value: t('Entry deadline'),
            title: (
              <>
                {tournament?.entryDeadline.format('DD.MM, HH:mm')} {tournament?.timeZone.abbreviation}
              </>
            ),
            icon: <PermContactCalendarOutlined sx={{ fontSize: '20px' }} />,
          },
        ]
      : []),
    ...(tournament?.tournamentCategory
      ? [
          {
            value: t('Entry Fee'),
            title: <>{getFeeRange(tournament?.tournamentCategory) || t('Free')}</>,
            icon: <MonetizationOn sx={{ fontSize: '20px' }} />,
          },
        ]
      : []),
    ...(tournament?.regulations
      ? [
          {
            title: t('Regulations'),
            value: (
              <Link underline="none" href={tournament?.regulations} sx={{ color: 'currentcolor', display: 'flex', alignItems: 'center' }}>
                {t('Download')} <DownloadOutlined sx={{ color: 'currentcolor', fontSize: '14px' }} />
              </Link>
            ),
            icon: <Gavel />,
          },
        ]
      : []),
  ];

  return (
    <Stack gap="14px">
      <Grid container spacing={2}>
        {(loading ? Array(8).fill({ value: undefined, title: undefined, icon: undefined }) : formatTabs).map((tab, index) => (
          <Grid item xs={6} md={4} lg={3} key={index}>
            <CustomPaperCard skeleton={loading} sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px', height: '68px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', flex: 1, overflow: 'hidden' }}>
                {tab.icon}
                <Stack gap="4px" sx={{ overflow: 'hidden', width: 'fit-content' }}>
                  <Typography variant="heading13" sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.2, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {tab.title}
                  </Typography>
                  <Typography variant="font21" sx={{ fontSize: { xs: 9, sm: 11 }, lineHeight: 1.2, color: '#86909F', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {tab.value}
                  </Typography>
                </Stack>
              </Box>
              {tab.scrollTo && (
                <CustomButton onClick={() => scrollToAndHighlight(tab.scrollTo)} size="small" variant="secondary" square>
                  <TablerIcon icon="viewfinder" fontSize="18px" />
                </CustomButton>
              )}
            </CustomPaperCard>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Format;
