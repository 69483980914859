import { FC, ReactElement } from 'react';
import { Box, Link, Stack, Typography } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { USER_ROLES } from 'src/constants';
import CustomModal from '../../../CustomComponents/CustomModal';
import GetFlag from '../../../../utils/getFlags';
import CustomAvatar from '../../../CustomComponents/CustomAvatar';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../CustomComponents/CustomButton';

interface ModalProps {
  entryData: any;
  children: ReactElement;
  onClick: () => void;
  setApplyModal: (state: boolean) => void;
  isDeleting: boolean;
  handleDelete: (entryData: any, handleClose: () => void) => void;
}

const InfoModal: FC<ModalProps> = ({ entryData, children, onClick, setApplyModal, isDeleting, handleDelete }) => {
  const { user, setLoginModalOpen } = useAuth();
  const allowedRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ORGANIZER];
  const { t } = useTranslation();
  const allowedRolesContactDetails = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ORGANIZER, USER_ROLES.FEDERATION, USER_ROLES.MANAGER, USER_ROLES.REFEREE];
  const showContact = allowedRolesContactDetails?.includes(user?.role) || user?.id === entryData?.user?.id;

  return (
    <CustomModal
      onClick={onClick}
      triggerEl={children}
      title={
        <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
          <GetFlag country={entryData?.user?.citizenship ? entryData?.user?.citizenship : entryData?.user?.nation} style={{ height: '12px', width: '15px', borderRadius: '3px' }} />

          <CustomAvatar src={entryData?.user?.avatar} size={26} seed={entryData?.user?.name + ' ' + entryData?.user?.surname} />
          <Typography variant="font18">{entryData?.user?.name + ' ' + entryData?.user?.surname}</Typography>
        </Box>
      }
    >
      {(handleClose) => (
        <Box sx={{ minWidth: '350px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
              borderTop: '1px solid rgba(0, 0, 0, 0.10)',
              paddingBlock: '14px',
              gap: '20px',
            }}
          >
            <Stack>
              <Typography variant="font21" color="#0d6f30" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                {entryData?.categoryId?.category?.name}
              </Typography>
              <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                {t('Category')}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="font21" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                #{entryData?.rank ?? 0}
              </Typography>
              <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                {t('Rank')}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
              paddingBlock: '14px',
              gap: '20px',
            }}
          >
            <Stack sx={{ maxWidth: '350px' }}>
              <Typography variant="font21" sx={{ lineHeight: '100%' }}>
                {entryData?.selfInformation || 'No details provided'}
              </Typography>
              <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                {t('About')} {entryData?.user?.name}
              </Typography>
            </Stack>
          </Box>
          {showContact && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
                paddingBlock: '14px',
                gap: '20px',
              }}
            >
              <Stack>
                <Typography variant="font21" color="#0d6f30" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                  <Link href={`mailto:${entryData?.user?.email}`} color="inherit">
                    {entryData?.user?.email}
                  </Link>
                </Typography>
                <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                  {t('Email')}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="font21" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                  <Link href={`tel:${entryData?.user?.phone}`} color="inherit">
                    {entryData?.user?.phone || 'N/A'}
                  </Link>
                </Typography>
                <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                  {t('Phone')}
                </Typography>
              </Stack>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
              paddingBlock: '14px',
              gap: '20px',
            }}
          >
            <Stack sx={{ maxWidth: '350px' }}>
              <Typography variant="font21" sx={{ lineHeight: '100%' }}>
                {entryData?.partnerInformation || 'No details provided'}
              </Typography>
              <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                Looking for
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              paddingBlock: '14px',
              gap: '20px',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Stack>
                <Typography variant="font21" sx={{ lineHeight: '100%' }}>
                  {entryData?.status === 'active' ? 'Searching...' : 'Partner found'}
                </Typography>
                <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                  Status
                </Typography>
              </Stack>
              {entryData?.partner?.id && entryData?.status === 'found' && (
                <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center', mt: '3px' }}>
                  <GetFlag country={entryData?.partner?.citizenship ? entryData?.partner?.citizenship : entryData?.partner?.nation} style={{ height: '12px', width: '15px', borderRadius: '3px' }} />

                  <CustomAvatar src={entryData?.partner?.avatar} size={18} seed={entryData?.partner?.name + ' ' + entryData?.partner?.surname} />
                  <Typography variant="font18">{entryData?.partner?.name + ' ' + entryData?.partner?.surname}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          {user?.id && entryData?.status === 'active' && user?.id !== entryData?.user?.id && (
            <CustomButton
              sx={{ width: '100%', mb: 1 }}
              size="medium"
              variant="primary"
              disabled={entryData?.cantRegister}
              onClick={() => {
                setApplyModal(true);
                handleClose();
              }}
            >
              Apply
            </CustomButton>
          )}
          {!user?.id && entryData?.status === 'active' && (
            <CustomButton sx={{ width: '100%' }} size="medium" variant="outline" onClick={() => setLoginModalOpen(true)}>
              Log in to apply
            </CustomButton>
          )}
          {(allowedRoles?.includes(user?.role) || user?.id === entryData?.user?.id) && (
            <CustomButton sx={{ width: '100%' }} size="medium" variant="primary" color="#C41E3A" onClick={() => handleDelete(entryData, handleClose)} disabled={isDeleting}>
              Delete
            </CustomButton>
          )}
        </Box>
      )}
    </CustomModal>
  );
};

export default InfoModal;
