import { Dispatch, FC, SetStateAction, Fragment, useState, useEffect } from 'react';

import CustomModal from '../../../CustomComponents/CustomModal';
import { Box, Stack } from '@material-ui/core';
import UserNameCell from '../../pairings/pairingsComponents/UserNameCell';
interface ModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  entryData: any;
}

const TeamMemberDetails: FC<ModalProps> = ({ openModal, setOpenModal, entryData }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Fragment>
      <CustomModal openOvveride={openModal} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" title={'Team members'}>
        <Box sx={{ maxHeight: { xs: 'none', sm: '250px' }, overflow: 'auto', padding: '10px' }}>
          <Stack gap="8px">
            {entryData?.map((item, i) => (
              <UserNameCell user={item?.user} fideTitle={item?.fideTitle} />
            ))}
          </Stack>
        </Box>
      </CustomModal>
    </Fragment>
  );
};

export default TeamMemberDetails;
