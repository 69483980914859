import React from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import getFormattedScore from './formattedScore';
import GetFlag from 'src/utils/getFlags';
import { parseScores } from 'src/constants';
import MatchesScores from './MatchesScores';
import CustomChip from '../CustomComponents/CustomChip';
import TeamMembers from '../shared/TeamMembers';

interface User {
  id: string;
  name: string;
  surname: string;
  nation?: string;
  citizenship?: string;
}

interface Entry {
  id: string;
  users: { user: User }[];
  registrationRequest?: { users: { user: User }[] };
}

interface Item {
  id: string;
  date: string;
  time: string;
  court?: { name: string };
  entry1?: Entry;
  entry1partner?: Entry;
  entry2?: Entry;
  entry2partner?: Entry;
  score?: string | any;
  scoreType?: string;
  winner?: { id: string };
}

interface MatchItemProps {
  item: Item;
  highlightText?: boolean;
  groupRefs?: React.RefObject<any>;
  userRefs?: React.RefObject<any>;
  segment?: any;
}

const MatchItem: React.FC<MatchItemProps> = ({ item, highlightText, segment }) => {
  const NoScore = ({ scoreType }) => (
    <Box sx={{ display: 'flex', padding: '5px' }}>
      <Typography
        sx={{
          display: 'flex',
          color: '#19366066',
          fontFamily: 'Inter',
          fontWeight: '500',
          fontSize: '10px',
          textTransform: 'uppercase',
        }}
      >
        {scoreType ?? ''}
      </Typography>
    </Box>
  );
  const renderUsers = (entry) => {
    const users = entry?.users || entry?.registrationRequest?.users || [];

    return (
      <TeamMembers
        team={entry?.registrationRequest?.team}
        users={users}
        seed={users?.registrationRequest?.seed}
        assignedSegment={users?.registrationRequest?.assignedSegment}
        additionalSegment={users?.entry?.registrationRequest?.additionalSegment}
        segment={segment}
        isShortName={true}
        showSeed={true}
        highlightWinner={entry?.id === entry?.winner?.id}
      />
    );
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: highlightText ? '' : '1px solid #0000001A',
          padding: '0px 0px 15px 0px',
          width: '100%',
          flexWrap: 'wrap',
          gap: { md: '0px', xs: '10px' },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { md: 'column', xs: 'row' }, gap: '10px', width: { md: '30%', xs: '100%' } }}>
          <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, gap: { md: '3px', xs: '5px' } }}>
            <Typography variant="font21" sx={{ lineHeight: '14.52px', color: '#0A2540' }}>
              {moment(item?.date).format('DD/MM')}
            </Typography>
            <Typography variant="font21" sx={{ lineHeight: '14.52px', color: '#0A254059' }}>
              {item?.time}
            </Typography>
          </Box>
          {item?.court?.name && (
            <CustomChip
              variant="secondary"
              size="small"
              color="#FAFAFA"
              sx={{
                maxWidth: 'max-content',
                width: '100%',
                height: '20px',
                padding: '5px 8px',
                border: '1px solid #EDF1F6',
                borderRadius: '4px',
                backgroundColor: '#FAFAFA',
              }}
            >
              <Typography variant="font16" sx={{ lineHeight: '20px', color: '#0A2540', whiteSpace: 'nowrap' }}>
                {item?.court?.name}
              </Typography>
            </CustomChip>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: { md: '70%', xs: '100%' } }}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%', gap: '5px' }}>
            <Typography variant="font16" sx={{ lineHeight: '14.52px', color: '#0A254059' }}>
              vs
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '3px' }}>{renderUsers(item?.entry1)}</Box>
              {item?.entry1partner && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '3px', mb: 0.5 }}>{renderUsers(item?.entry1partner)}</Box>}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '3px' }}>{renderUsers(item?.entry2)}</Box>
              {item?.entry2partner && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '3px' }}>{renderUsers(item?.entry2partner)}</Box>}
            </Box>
          </Box>
          <Box sx={{ border: '1px solid #EDF1F6', height: 'max-content', borderRadius: '6px' }}>
            {item?.score ? (
              typeof item?.score === 'string' ? (
                <>
                  {item?.winner?.id === item?.entry1?.id ? (
                    <MatchesScores scores1={parseScores(item?.score)?.firstArray} scores2={parseScores(item?.score)?.secondArray} winner={true} />
                  ) : (
                    <MatchesScores scores1={parseScores(item?.score)?.secondArray} scores2={parseScores(item?.score)?.firstArray} winner={false} />
                  )}
                  {item?.winner?.id === item?.entry2?.id ? (
                    <MatchesScores scores1={parseScores(item?.score)?.firstArray} scores2={parseScores(item?.score)?.secondArray} winner={true} />
                  ) : (
                    <MatchesScores scores1={parseScores(item?.score)?.secondArray} scores2={parseScores(item?.score)?.firstArray} winner={false} />
                  )}
                </>
              ) : (
                <>{getFormattedScore(item?.score, item, item?.scoreType)}</>
              )
            ) : (
              <NoScore scoreType={item?.scoreType} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MatchItem;
