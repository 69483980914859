import { gql } from '@apollo/client';
const ALLGROUPSDATA = gql`
  query GetGroupsData($filter: ListGroupInput, $page: Int, $limit: Int, $name: String) {
    allGroups(filter: $filter, page: $page, limit: $limit, name: $name) {
      groups {
        id
        name
        segment
        tournament {
          id
          title
        }
        segment
        tournamentCategory {
          id
          category {
            id
            name
            type
            identifyTeamMember
          }
        }

        displayPlayOrder
        displayMatches
        participantsAddScore
        hide
        comment
        isDelete
        groupType
        entries {
          id
          place
          registrationRequest {
            id
            seed
            segment
            assignedSegment
            additionalSegment
            team {
              id
              title
              image
            }
            users {
              id
              user {
                id
                name
                surname
                nation
              }
            }
          }
        }
      }
      total
    }
  }
`;
export default ALLGROUPSDATA;
