import { gql } from '@apollo/client';

const SPORTS_FISHING_RESULTS = gql`
  query sportFishingResults($filter: ListSportFishingResultInput) {
    sportFishingResults(filter: $filter) {
      id
      title
      createdAt
      isPublish
      stage {
        id
        name
        isPublish
      }
      categoryId {
        id
      }
      matrics {
        unit
        title
        id
      }
      entries {
        id
        weight
        location
        points

        matrics {
          points
          id
          media
          sportFishingMatric {
            id
            unit
            title
          }
        }
        entry {
          id
          team {
            id
            title
            image
          }
          users {
            user {
              name
              surname
              avatar
              nation
            }
          }
        }
      }
    }
  }
`;

export default SPORTS_FISHING_RESULTS;
