import moment from 'moment';
import { getCurrencySymbol } from './utils/helperFunction';
import { EventEmitter } from 'events';
export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};
export const SkeletonData = [1, 2, 3, 4, 5, 6, 7];

export const USER_ROLES = {
  SUPER_ADMIN: 'superAdmin',
  USER: 'user',
  ADMIN: 'admin',
  ORGANIZER: 'organizer',
  REFEREE: 'referre',
  ATHLETE: 'athlete',
  FEDERATION: 'federation',
  COACH: 'coach',
  MANAGER: 'manager',
  MEMBER: 'Member',
};

export const ALL_SPORTS = [
  {
    value: 'Tennis',
    label: 'Tennis',
  },
  {
    label: 'Beach Tennis',
    value: 'Beach Tennis',
  },
  {
    label: 'Beach Volleyball',
    value: 'Beach Volleyball',
  },
  {
    label: 'Padel',
    value: 'Padel',
  },
  {
    label: 'Fishing',
    value: 'Fishing',
  },
];
export const TournamentSkeletonLimit = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

export const skeletonCount = [{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }, { key: 6 }, { key: 7 }, { key: 8 }, { key: 9 }];
export const TeamMembers = [
  {
    id: 0,
    name: 'John Doe',
    image: '/images/avatar2.svg',
    country: 'Latvia',
    Position: 'CAPTAIN',
    icon: '/images/crownWin.svg',
    color: '#FF7F37',
    status: 'Active',
  },
  {
    id: 1,
    name: 'John Doe',
    image: '/images/avatar2.svg',
    country: 'Latvia',
    Position: 'MANAGER',
    icon: '/images/manager.svg',
    color: '#FF5733',
    status: 'Active',
  },
  {
    id: 2,
    name: 'John Doe',
    image: '/images/avatar2.svg',
    country: 'Latvia',
    Position: 'ATHLETE',
    icon: '/images/crown.svg',
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    status: 'Active',
  },
  {
    id: 3,
    name: 'John Doe',
    image: '/images/avatar2.svg',
    country: 'Latvia',
    Position: 'ATHLETE',
    icon: '/images/crown.svg',
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    status: 'Active',
  },
  {
    id: 4,
    name: 'John Doe',
    image: '/images/avatar2.svg',
    country: 'Latvia',
    Position: 'ATHLETE',
    icon: '/images/crown.svg',
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    status: 'Active',
  },
];
export const Teams = [
  {
    id: 0,
    name: 'Feeder LV',
    image: '/images/teampf.png',
    country: 'Latvia',
    sport: 'Feeder',
    icon: '/images/feeder.svg',
    members: ['/images/mpf.svg', '/images/npf2.svg', '/images/npf2.svg', '/images/plustwo.svg'],
    status: 'Active',
  },
  {
    id: 1,
    name: 'Feeder LV',
    image: '/images/teampf.png',
    country: 'Latvia',
    sport: 'Feeder',
    icon: '/images/feeder.svg',
    members: ['/images/mpf.svg', '/images/npf2.svg', '/images/npf2.svg', '/images/plustwo.svg'],
    status: 'Active',
  },
  {
    id: 2,
    name: 'Feeder LV',
    image: '/images/teampf.png',
    country: 'Latvia',
    sport: 'Feeder',
    icon: '/images/feeder.svg',
    members: ['/images/mpf.svg', '/images/npf2.svg', '/images/npf2.svg', '/images/plustwo.svg'],
    status: 'Active',
  },
];

export const News = [
  {
    label: 'Tennis Europe Junior Tour',
    description: 'Lorem ipsum dolor sit amet consectetur. Turpis feugiat quis lorem dolor elit risus sit...',
    image: '/images/ncard.svg',
  },
  {
    label: 'Tennis Europe Junior Tour',
    description: 'Lorem ipsum dolor sit amet consectetur. Turpis feugiat quis lorem dolor elit risus sit...',
    image: '/images/ncard.svg',
    isFramed: true,
  },
  {
    label: 'Tennis Europe Junior Tour',
    description: 'Lorem ipsum dolor sit amet consectetur. Turpis feugiat quis lorem dolor elit risus sit...',
    image: '/images/ncard.svg',
    isFramed: true,
  },
  {
    label: 'Tennis Europe Junior Tour',
    description: 'Lorem ipsum dolor sit amet consectetur. Turpis feugiat quis lorem dolor elit risus sit...',
    image: '/images/ncard.svg',
  },
];

export const leagueCardDetails = [
  {
    id: 0,
    sports: 'Beach Volleyball',
    image: '/images/fed.svg',
    title: 'FÉDÉRATION FRANÇAISE DE TENNIS',
    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor',
    poweredBy: 'BeachTennis.IO',
    icon: '/images/NoPath.svg',
    stats: [
      {
        icon: '/images/leaguesicon.svg',
        heading: 'Leagues',
        content: '13',
      },
      {
        icon: '/images/cup.svg',
        heading: 'Tournaments',
        content: '12',
      },
      {
        icon: '/images/person.svg',
        heading: 'Athletes',
        content: '25',
      },
    ],
  },
  {
    id: 1,
    sports: 'Beach Volleyball',
    image: '/images/fed.svg',
    title: 'FÉDÉRATION FRANÇAISE DE TENNIS',
    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor',
    poweredBy: 'BeachTennis.IO',
    icon: '/images/NoPath.svg',
    stats: [
      {
        icon: '/images/leaguesicon.svg',
        heading: 'Leagues',
        content: '13',
      },
      {
        icon: '/images/cup.svg',
        heading: 'Tournaments',
        content: '12',
      },
      {
        icon: '/images/person.svg',
        heading: 'Athletes',
        content: '25',
      },
    ],
  },
  {
    id: 2,
    sports: 'Beach Volleyball',
    image: '/images/fed.svg',
    title: 'FÉDÉRATION FRANÇAISE DE TENNIS',
    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor',
    poweredBy: 'BeachTennis.IO',
    icon: '/images/NoPath.svg',
    stats: [
      {
        icon: '/images/leaguesicon.svg',
        heading: 'Leagues',
        content: '13',
      },
      {
        icon: '/images/cup.svg',
        heading: 'Tournaments',
        content: '12',
      },
      {
        icon: '/images/person.svg',
        heading: 'Athletes',
        content: '25',
      },
    ],
  },
];

export const RequestsDetails = [
  {
    id: 0,
    user: 'JEVGENIJS FELDMANS',
    entry: 'Participants card',
    type: 'Registration on tournament',
    category: 'MD[1]',
  },
  {
    id: 1,
    user: 'JEVGENIJS FELDMANS',
    entry: 'Participants card',
    type: 'Registration on tournament',
    category: 'MD[1]',
  },
  {
    id: 2,
    user: 'JEVGENIJS FELDMANS',
    entry: 'Participants card',
    type: 'Registration on tournament',
    category: 'MD[1]',
  },
];

export const BookingsDetails = [
  {
    id: 1,
    details: [
      { id: 0, time: '09:00 - 09:30', list: '0/4 [Full List]' },
      { id: 1, time: '09:30 - 10:00', list: '0/4 [Full List]' },
      { id: 2, time: '10:00 - 10:30', list: '0/4 [Full List]' },
      { id: 3, time: '10:30 - 11:00', list: '0/4 [Full List]' },
    ],
  },
  {
    id: 2,
    details: [
      { id: 0, time: '09:00 - 09:30', list: '0/4 [Full List]' },
      { id: 1, time: '09:30 - 10:00', list: '0/4 [Full List]' },
      { id: 2, time: '10:00 - 10:30', list: '0/4 [Full List]' },
      { id: 3, time: '10:30 - 11:00', list: '0/4 [Full List]' },
    ],
  },
  {
    id: 3,
    details: [
      { id: 0, time: '09:00 - 09:30', list: '0/4 [Full List]' },
      { id: 1, time: '09:30 - 10:00', list: '0/4 [Full List]' },
      { id: 2, time: '10:00 - 10:30', list: '0/4 [Full List]' },
      { id: 3, time: '10:30 - 11:00', list: '0/4 [Full List]' },
    ],
  },
  {
    id: 4,
    details: [
      { id: 0, time: '09:00 - 09:30', list: '0/4 [Full List]' },
      { id: 1, time: '09:30 - 10:00', list: '0/4 [Full List]' },
      { id: 2, time: '10:00 - 10:30', list: '0/4 [Full List]' },
      { id: 3, time: '10:30 - 11:00', list: '0/4 [Full List]' },
    ],
  },
  {
    id: 5,
    details: [
      { id: 0, time: '09:00 - 09:30', list: '0/4 [Full List]' },
      { id: 1, time: '09:30 - 10:00', list: '0/4 [Full List]' },
      { id: 2, time: '10:00 - 10:30', list: '0/4 [Full List]' },
      { id: 3, time: '10:30 - 11:00', list: '0/4 [Full List]' },
    ],
  },
];

export const PartnerData = [
  {
    id: 0,
    partnerName: 'JEVGENIJS FELDMANS',
    category: '1[MD]',
    rank: 316,
    btn: 'EXPIRED',
    status: false,
  },
  {
    id: 1,
    partnerName: 'JEVGENIJS FELDMANS',
    category: '1[MD]',
    rank: 316,
    btn: 'EXPIRED',
    status: false,
  },
  {
    id: 2,
    partnerName: 'JEVGENIJS FELDMANS',
    category: '1[MD]',
    rank: 316,
    btn: 'APPLY',
    status: true,
  },
  {
    id: 3,
    partnerName: 'JEVGENIJS FELDMANS',
    category: '1[MD]',
    rank: 316,
    btn: 'EXPIRED',
    status: false,
  },
];

export const singleAthletes = [
  {
    id: 1,
    athleteName: 'Nick Fisher',
    rank: 'First Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#FF8E25',
    icon: '/images/trophyn.svg',
  },
  {
    id: 2,
    athleteName: 'Nick Fisher',
    rank: 'Second Place',
    country: 'Latvia',
    prize: '$500',
    points: '47.0',
    color: '#8793B4',
    icon: '/images/trophy1.svg',
  },
  {
    id: 3,
    athleteName: 'Nick Fisher',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
    icon: '/images/trophy2.svg',
  },
  {
    id: 4,
    athleteName: 'Nick Fisher',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
  {
    id: 5,
    athleteName: 'Nick Fisher',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
  {
    id: 6,
    athleteName: 'Nick Fisher',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
];
export const doubleAthletes = [
  {
    id: 1,
    athleteName: 'J. Doe',
    athleteName1: 'J. Feldmans',
    rank: 'First Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#FF8E25',
    icon: '/images/trophyn.svg',
  },
  {
    id: 2,
    athleteName: 'J. Doe',
    athleteName1: 'J. Feldmans',
    rank: 'Second Place',
    country: 'Latvia',
    prize: '$500',
    points: '47.0',
    color: '#8793B4',
    icon: '/images/trophy1.svg',
  },
  {
    id: 3,
    athleteName: 'J. Doe',
    athleteName1: 'J. Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
    icon: '/images/trophy2.svg',
  },
  {
    id: 4,
    athleteName: 'J. Doe',
    athleteName1: 'J. Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
  {
    id: 5,
    athleteName: 'J. Doe',
    athleteName1: 'J. Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
  {
    id: 6,
    athleteName: 'J. Doe',
    athleteName1: 'J. Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
];
export const teamAthletes = [
  {
    id: 1,
    teamName: 'Jevgenijs Feldmans',
    rank: 'First Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#FF8E25',
    icon: '/images/trophyn.svg',
  },
  {
    id: 2,
    teamName: 'Jevgenijs Feldmans',
    rank: 'Second Place',
    country: 'Latvia',
    prize: '$500',
    points: '47.0',
    color: '#8793B4',
    icon: '/images/trophy1.svg',
  },
  {
    id: 3,
    teamName: 'Jevgenijs Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
    icon: '/images/trophy2.svg',
  },
  {
    id: 4,
    teamName: 'Jevgenijs Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
  {
    id: 5,
    teamName: 'Jevgenijs Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
  {
    id: 6,
    teamName: 'Jevgenijs Feldmans',
    rank: 'Third Place',
    country: 'Latvia',
    prize: '$1000',
    points: '50.0',
    color: '#B08B68',
  },
];

export const singleResult = [
  {
    id: 1,
    team: 'Team Name',
    athlete: 'Nick Fisher',
    location: '/images/location.svg',
    weight: '2.432 KG.',
    points: '1',
  },
  {
    id: 2,
    team: 'Team Name',
    athlete: 'Nikita',
    location: '/images/location.svg',
    weight: '2.432 KG.',
    points: '1',
  },
  {
    id: 3,
    team: 'Team Name',
    athlete: 'Nick',
    location: '/images/location.svg',
    weight: '2.432 KG.',
    points: '1',
  },
  {
    id: 3,
    team: 'Team Name',
    athlete: 'Nick',
    location: '/images/location.svg',
    weight: '2.432 KG.',
    points: '1',
  },
];

export const teamResult = [
  {
    id: 1,
    team: 'Team Name',
    athlete: 'ENNO TOOMLA (EST)',
    fish1: '2.432 KG.',
    fish2: '2.432 KG.',
    fish3: '2.432 KG.',
    fish4: '2.432 KG.',
    total: '2.432 KG.',
    points: '1',
  },
  {
    id: 2,
    team: 'Team Name',
    athlete: 'ENNO TOOMLA (EST)',
    fish1: '2.432 KG.',
    fish2: '2.432 KG.',
    fish3: '2.432 KG.',
    fish4: '2.432 KG.',
    total: '2.432 KG.',
    points: '1',
  },
  {
    id: 3,
    team: 'Team Name',
    athlete: 'ENNO TOOMLA (EST)',
    fish1: '2.432 KG.',
    fish2: '2.432 KG.',
    fish3: '2.432 KG.',
    fish4: '2.432 KG.',
    total: '2.432 KG.',
    points: '1',
  },
  {
    id: 4,
    team: 'Team Name',
    athlete: 'ENNO TOOMLA (EST)',
    fish1: '2.432 KG.',
    fish2: '2.432 KG.',
    fish3: '2.432 KG.',
    fish4: '2.432 KG.',
    total: '2.432 KG.',
    points: '1',
  },
  {
    id: 5,
    team: 'Team Name',
    athlete: 'ENNO TOOMLA (EST)',
    fish1: '2.432 KG.',
    fish2: '2.432 KG.',
    fish3: '2.432 KG.',
    fish4: '2.432 KG.',
    total: '2.432 KG.',
    points: '1',
  },
];
export const FishResults = [
  { id: 1, icon: '/images/fishmini.svg', info: 'Asaris, 27.00cm2, 729.00 g.' },
  { id: 2, icon: '/images/fishmini.svg', info: 'Asaris, 27.00cm2, 629.00 g.' },
  { id: 3, icon: '/images/fishmini.svg', info: 'Asaris, 25.00cm2, 529.00 g.' },
  { id: 4, icon: '/images/fishmini.svg', info: 'Asaris, 23.00cm2, 729.00 g.' },
];

export const languageOptions = [
  { name: 'Latvian', value: 'lat' },
  { name: 'English American', value: 'eng_us' },
  { name: 'English British', value: 'eng_uk' },
  { name: 'Spain', value: 'esp' },
];

export const Coach = [
  { name: 'coach1', value: 'c1' },
  { name: 'coach2', value: 'c2' },
  { name: 'coach3', value: 'c3' },
  { name: 'coach4', value: 'c4' },
];

export const ClubDetails = [
  { name: 'club1', value: 'cl1' },
  { name: 'club2', value: 'cl2' },
  { name: 'club3', value: 'cl3' },
  { name: 'club4', value: 'cl4' },
];
export const tabs = [
  {
    label: 'General',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
  },
  {
    label: 'Schedule',
    value: 'events',
    icon: '/images/event.svg',
  },
  {
    label: 'Participants',
    value: 'participants',
    icon: '/images/updated/Icon/Athletes.svg',
  },

  {
    label: 'Partner Search',
    value: 'search',
    icon: '/images/updated/Icon/Search.svg',
  },
  {
    label: 'Groups',
    value: 'groups',
    icon: '/images/updated/Icon/team.svg',
  },
  {
    label: 'Draws',
    value: 'draws',
    icon: '/images/updated/Icon/sitemap.svg',
  },
  {
    label: 'Live Standings',
    value: 'livestandings',
    icon: '/images/updated/Icon/live.svg',
  },
  {
    label: 'Order of play',
    value: 'order_of_play',
    icon: '/images/updated/Icon/order.svg',
  },
  {
    label: 'Gallery',
    value: 'gallery',
    icon: '/images/updated/Gallery.svg',
  },
  {
    label: 'Pairings',
    value: 'pairings',
    icon: '/images/updated/Icon/knight.svg',
  },
  {
    label: 'Standings',
    value: 'pairings-standings',
    icon: '/images/updated/chess-king.svg',
  },

  // {
  //   label: "Bookings",
  //   value: "bookings",
  //   icon: "/images/updated/Icon/booked.svg",
  // },
  // {

  // {
  //   label: "Requests",
  //   value: "requests",
  //   icon: "/images/updated/Icon/Request.svg",
  // },
  {
    label: 'Final standings',
    value: 'points',
    icon: '/images/updated/Icon/point.svg',
  },
  {
    label: 'List & Results',
    value: 'results',
    icon: '/images/updated/Icon/result.svg',
  },
];
export const AthleteModalTabs = [
  {
    label: 'My entries',
    value: 'entries',
    icon: '/images/updated/Icon/settings.svg',
  },
  {
    label: 'My matches',
    value: 'matches',
    icon: '/images/event.svg',
  },
  {
    label: 'Pending payments',
    value: 'payments',
    icon: '/images/updated/Icon/Athletes.svg',
  },
];
export const leagueTabs = [
  {
    label: 'General',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
  },
  {
    label: 'Tournaments',
    value: 'tournaments',
    icon: '/images/updated/Tournament.svg',
  },
  {
    label: 'Rankings',
    value: 'ranking',
    icon: '/images/updated/ranking.svg',
  },
  {
    label: 'News',
    value: 'news',
    icon: '/images/updated/Icon/News.svg',
  },
  {
    label: 'Gallery',
    value: 'gallery',
    icon: '/images/updated/Gallery.svg',
  },
  {
    label: 'Documents',
    value: 'documents',
    icon: '/images/updated/Document.svg',
  },

  {
    label: 'Contact',
    value: 'contact',
    icon: '/images/updated/Icon/booked.svg',
  },
];
export const orgTabs = [
  {
    label: 'General',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
  },
  {
    label: 'Tournaments',
    value: 'tournaments',
    icon: '/images/updated/Tournament.svg',
  },
  {
    label: 'Rankings',
    value: 'ranking',
    icon: '/images/updated/ranking.svg',
  },
  {
    label: 'News',
    value: 'news',
    icon: '/images/updated/Icon/News.svg',
  },
  {
    label: 'Gallery',
    value: 'gallery',
    icon: '/images/updated/Gallery.svg',
  },
  {
    label: 'Documents',
    value: 'documents',
    icon: '/images/updated/Document.svg',
  },

  {
    label: 'Contact',
    value: 'contact',
    icon: '/images/updated/Icon/booked.svg',
  },
];
export const LeagueGallery = [
  { id: 0, img: '/images/tennis1.png' },
  { id: 1, img: '/images/tennis2.png' },
  { id: 2, img: '/images/tennis3.png' },
  { id: 3, img: '/images/tennis4.png' },
  { id: 4, img: '/images/tennis5.png' },
];

export const LeagueLogos = ['/images/heros.svg', '/images/wilson.svg', '/images/addidas.svg', '/images/wick.svg', '/images/space.svg', '/images/heros.svg'];

export const StatsTabs = [
  { key: 1, title: 'Top of fishes caught', value: 'fish' },
  { key: 2, title: 'Top of biggest fish', value: 'bigFish' },
];
export const clubTabs = [
  {
    label: 'General',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
  },
  {
    label: 'Tournaments',
    value: 'tournaments',
    icon: '/images/updated/Tournament.svg',
  },
  {
    label: 'Teams',
    value: 'teams',
    icon: '/images/updated/ranking.svg',
  },
  {
    label: 'Members',
    value: 'students',
    icon: '/images/updated/Icon/News.svg',
  },
  {
    label: 'Contact',
    value: 'contact',
    icon: '/images/updated/Icon/booked.svg',
  },
];
export const coachTabs = [
  {
    label: 'General',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
  },
  // {
  //   label: "Tournaments",
  //   value: "tournaments",
  //   icon: "/images/updated/Tournament.svg",
  // },
  // {
  //   label: "Teams",
  //   value: "teams",
  //   icon: "/images/updated/ranking.svg",
  // },
  {
    label: 'Students',
    value: 'students',
    icon: '/images/updated/Icon/News.svg',
  },
  {
    label: 'Contact',
    value: 'contact',
    icon: '/images/updated/Icon/booked.svg',
  },
];
export const teamTabs = [
  {
    label: 'General',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
  },
  // {
  //   label: 'Tournaments',
  //   value: 'tournaments',
  //   icon: '/images/updated/Tournament.svg',
  // },
  {
    label: 'Members',
    value: 'members',
    icon: '/images/updated/ranking.svg',
  },
];

export const dummyFederation = {
  id: 1,
  title: 'BT STARS Ziemas Pludmales Tenisa Čempionāts 2022-2023',
};

export const categories = [
  {
    id: 0,
    title: 'Boys 18+',
  },
  {
    id: 1,
    title: 'Girls 18+',
  },
];

export const profileIcons = ['/images/profileinsta.svg', '/images/profiletwitter.svg', '/images/profilefb.svg', '/images/profilelink.svg', '/images/phone.svg'];

export const userProfileTabs = [
  {
    label: 'Overview',
    value: 'overview',
    icon: '/images/updated/Home.svg',
  },

  {
    label: 'Leagues',
    value: 'leagues',
    icon: '/images/updated/Leagues.svg',
  },
  {
    label: 'Tournaments',
    value: 'tournaments',
    icon: '/images/updated/Tournament.svg',
  },
  {
    label: 'Matches',
    value: 'matches',
    icon: '/images/updated/Matches.svg',
  },
  {
    label: 'Rankings',
    value: 'ranking',
    icon: '/images/updated/ranking.svg',
  },
];
export const myCareerTabs = [
  {
    label: 'Overview',
    value: 'overview',
    icon: '/images/updated/Home.svg',
  },

  {
    label: 'My leagues',
    value: 'leagues',
    icon: '/images/updated/Leagues.svg',
  },
  {
    label: 'My tournaments',
    value: 'tournaments',
    icon: '/images/updated/Tournament.svg',
  },
  {
    label: 'My matches',
    value: 'matches',
    icon: '/images/updated/Matches.svg',
  },

  {
    label: 'My entries',
    value: 'entries',
    icon: '/images/entryIcon.svg',
  },
  {
    label: 'My rankings',
    value: 'rankings',
    icon: '/images/updated/ranking.svg',
  },
  {
    label: 'My teams',
    value: 'teams',
    icon: '/images/updated/Icon/teams.svg',
  },
];
export const OrganizerProfileTabs = [
  {
    label: 'General',
    value: 'general',
  },
  // {
  //   label: "Tournaments",
  //   value: "tournaments",
  // },
  // {
  //   label: "Other Events",
  //   value: "events",
  // },
];
export const Gender = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];

export const NextMatches = [
  {
    place: '/images/updated/GOld.svg',
    name: 'GTT PRO TOUR',
    cat: 'Men Pro',
  },
  {
    place: '/images/updated/silver.svg',
    name: 'GTT PRO TOUR',
    cat: 'Men Pro',
  },
  {
    place: '/images/updated/silver.svg',
    name: 'GTT PRO TOUR',
    cat: 'Men Pro',
  },
  {
    place: '/images/updated/silver.svg',
    name: 'GTT PRO TOUR',
    cat: 'Men Pro',
  },
];

export const Leagues = [
  {
    count: '2500',
    title: 'Tournaments',
    icon: '/images/updated/Trophy.svg',
  },
  // {
  //   count: "100",
  //   title: "Matches",
  //   icon: "/images/updated/LeaguesUp.svg",
  // },
  {
    count: '134',
    title: 'Athletes',
    icon: '/images/updated/AthletesUp.svg',
  },
];
export const Tournaments = [
  {
    count: '2500',
    title: 'Tournaments',
    icon: '/images/updated/Trophyb.svg',
  },
  {
    count: '100',
    title: 'Matches',
    icon: '/images/updated/Leaguesb.svg',
  },
  {
    count: '134',
    title: 'Athletes',
    icon: '/images/updated/Athletesb.svg',
  },
];
export const SelectSports = [
  {
    img: '/images/updated/sport1.png',
    sport: 'Tennis',
    count: '2054 Tournaments',
  },
  {
    img: '/images/updated/sport2.png',
    sport: 'Beach Volleyball',
    count: '2054 Tournaments',
  },
  {
    img: '/images/updated/sport3.png',
    sport: 'Beach Tennis',
    count: '2054 Tournaments',
  },
  {
    img: '/images/updated/sport4.png',
    sport: 'Padel',
    count: '2054 Tournaments',
  },
  {
    img: '/images/updated/sport5.png',
    sport: 'Sport Fishing',
    count: '2054 Tournaments',
  },
];
export const partnerImges = [
  {
    img: '/images/updated/partner1.svg',
  },
  {
    img: '/images/updated/partner2.svg',
  },
  {
    img: '/images/updated/partner3.svg',
  },
  {
    img: '/images/updated/partner4.svg',
  },
  {
    img: '/images/updated/partner5.svg',
  },
];

export const RegInfo = [
  {
    icon: '/images/updated/register.svg',
    title: 'Register',
    details: '100',
  },
  {
    icon: '/images/updated/Confirm.svg',
    title: 'Confirm',
    details: '50',
  },
  {
    icon: '/images/updated/Time.svg',
    title: 'Registration has closed',
    details: '',
  },
];

export const CustomPackages = [
  {
    title: 'Custom',
    limit: '/month',
    currency: '€',
    newPrice: '199',
    oldPrice: '299',
    features: [
      { text: 'Features of Basic plan', action: '' },
      { text: 'Custom domain name / platform', action: '' },
      { text: 'Custom landing and about organization pages', action: '' },
      // { text: 'Up to 3 hours per month of free customization', action: '' },
      { text: 'Up to 5 team members', action: 'chip' },
      // { text: 'Personal server', action: '' },
      {
        text: 'Earn with Tournated Monetization',
        action: 'openServiceFeeModal',
      },
    ],
  },
  {
    title: 'Custom Plus',
    limit: '/month',
    currency: '€',
    newPrice: '299',
    oldPrice: '399',
    features: [
      { text: 'Features of Custom plan', action: '' },
      // { text: 'Up to 5 hours per month of free customization', action: '' },
      { text: 'Up to 50 team members', action: '' },
      {
        text: 'Earn with Tournated Monetization',
        action: 'openServiceFeeModal',
      },
    ],
  },
  {
    title: 'Custom Ultra',
    limit: '/month',
    currency: '€',
    newPrice: '399',
    oldPrice: '499',
    features: [
      { text: 'Features of Custom Plus plan', action: '' },
      // { text: 'Up to 8 hours per month of free customization', action: '' },
      { text: 'Unlimited number of team members', action: '' },
      {
        text: 'Earn with Tournated Monetization',
        action: 'openServiceFeeModal',
      },
    ],
  },
];
export const ManagerPackages = [
  {
    title: 'Free plan',
    limit: '/month',
    currency: '€',
    newPrice: '0',
    oldPrice: '',
    features: [
      {
        text: 'Add and manage unlimited number of tournaments in a default Tournated league',
        action: '',
      },
      { text: 'Entry management', action: '' },
      {
        text: 'Automated groups, draws, live standings and order of play',
        action: '',
      },
      {
        text: 'List & Results (Custom metrics), Pairings (Swiss System)',
        action: '',
      },
      { text: 'Results management', action: '' },
      { text: 'Final standings', action: '' },
      {
        text: 'Third-party league integrations (IPIN, FIDE, DUPR and more)',
        action: '',
      },
      { text: 'Email notifications', action: '' },
      { text: 'Online payments', action: '' },
      {
        text: 'Earn with Tournated Monetization',
        action: 'openServiceFeeModal',
      },
    ],
  },
  {
    title: 'Pro plan',
    limit: '/month',
    currency: '€',
    newPrice: 'Free',
    oldPrice: '39.99',
    features: [
      { text: 'Features of Free plan', action: '' },
      { text: 'Setup your own leagues', action: '' },
      { text: 'Team management & Analytics', action: '' },
      { text: 'Licence management', action: '' },
      { text: 'Automated rankings', action: '' },
      { text: 'Practice courts / transfer booking', action: '' },
      { text: 'Personal customer support', action: '' },
      {
        text: 'Earn with Tournated Monetization',
        action: 'openServiceFeeModal',
      },
    ],
  },
];
export const OnboardingSteps = [
  {
    id: 0,
    icon: '/images/updated/1.svg',
    label: 'Organization account',
  },
  {
    id: 1,
    icon: '/images/updated/2.svg',
    label: 'Platform general',
  },
  {
    id: 2,
    icon: '/images/updated/3.svg',
    label: 'Appearance',
  },
  {
    id: 3,
    icon: '/images/updated/4.svg',
    label: 'Company details',
  },
  {
    id: 4,
    icon: '/images/updated/5.svg',
    label: 'Landing & Navigation',
  },
  { id: 5, icon: '/images/updated/6.svg', label: 'Integrations' },
  { id: 6, icon: '/images/updated/7.svg', label: 'Additional requests' },
];

export const UserSettingsSteps = [
  {
    id: 0,
    icon: '/images/updated/1.svg',
    label: 'General',
  },
  {
    id: 1,
    icon: '/images/updated/2.svg',
    label: 'Career',
  },
  {
    id: 2,
    icon: '/images/updated/3.svg',
    label: 'Third Party Licences',
  },
  {
    id: 3,
    icon: '/images/updated/4.svg',
    label: 'Password',
  },
  // {
  //   id: 4,
  //   icon: "/images/updated/5.svg",
  //   label: "Login settings",
  // },
  // { id: 5, icon: "/images/updated/6.svg", label: "Management" },
];

export const Templates = ['/images/updated/temp.png', '/images/updated/temp.png', '/images/updated/temp.png'];
export const IntegrationQuestions = [
  {
    id: 0,
    name: 'licenseMemberships',
    question: 'Are you looking to have licences/memberships in the platform?',
    desc: 'Make sure to get familiar with Tournated service fees',
    checked: false,
  },
  {
    id: 1,
    name: 'onlinePayments',
    question: 'Are you looking to have online payments for entry fees?',
    desc: 'Make sure to get familiar with Tournated service fees',
    checked: false,
  },
  {
    id: 2,
    name: 'onlineBookingCourts',
    question: 'Are you looking to integrate online booking for courts? (coming soon)',
    desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    checked: false,
  },
  {
    id: 3,
    name: 'liveStreaming',
    question: 'Are you looking to integrate live streaming? (coming soon)',
    desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    checked: false,
  },
  {
    id: 4,
    name: 'liveScoring',
    question: 'Are you looking to integrate live scoring? (coming soon)',
    desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    checked: false,
  },
];
export const AdditionalServices = [
  {
    id: 0,
    serviceName: 'SMM',
    price: '400 EUR/ Month',
    checked: false,
  },
  {
    id: 1,
    serviceName: 'Graphics for SMM',
    price: '300 EUR/ Month',
    checked: false,
  },
  {
    id: 2,
    serviceName: 'Videography Photography',
    price: '400EUR / month or 150EUR per event',
    checked: false,
  },
];
export const PlansSkeleton = [1, 2, 3];

export const Currency = [
  {
    title: 'Usd',
    value: 'usd',
  },
  {
    title: 'Euro',
    value: 'euro',
  },
];

export const OrgTypes = [
  {
    title: 'ORG 1',
    value: 'org1',
  },
  {
    title: 'ORG 2',
    value: 'org2',
  },
];
export const PlatformTypes = [
  {
    title: 'International',
    value: 'org1',
  },
  {
    title: 'National',
    value: 'org2',
  },
];
export const Timezones = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg',
];
export const formatSeed = (player, segment) => {
  if (!player) return '';

  const { seed, assignedSegment, additionalSegment } = player;

  function getShort(wildCard) {
    switch (wildCard) {
      case 'MDWC':
        return 'WC';
      case 'QWC':
        return 'WC';
      case 'QW':
        return 'Q';
      default:
        return wildCard;
    }
  }

  let result = '';
  let seedSegment = assignedSegment;
  let seedValue = seed;

  if (segment === 'MD' && segment !== 'Q' && additionalSegment) {
    seedSegment = additionalSegment;
    seedValue = additionalSegment === 'QW' ? '' : seed;
  } else if (segment === 'Q' && assignedSegment === 'QW') {
    seedSegment = '';
  } else {
    seedSegment = assignedSegment !== 'Q' && assignedSegment !== 'MD' ? assignedSegment : '';
  }

  if (seedValue || assignedSegment) {
    result += seedValue || seedSegment ? ` [${seedValue || ''}${seedValue && seedSegment ? ', ' : ''}${getShort(seedSegment) || ''}]` : '';
  }

  return result;
};

export const OrgTypesDef = [
  {
    title: 'Local organization',
    value: 'Local organization',
  },
  {
    title: 'National organization',
    value: 'National organization',
  },
  {
    title: 'International organization',
    value: 'International organization',
  },
  {
    title: 'Sports club',
    value: 'Sports club',
  },
  {
    title: 'Local organizer',
    value: 'Local organizer',
  },
  {
    title: 'Sports ministry',
    value: 'Sports ministry',
  },
  {
    title: 'School',
    value: 'School',
  },
  {
    title: 'Union',
    value: 'Union',
  },
];
export const processLongName = (name) => {
  if (name && name?.length > 22) {
    const words = name?.split(/\s+/);
    if (words?.length === 3) {
      return `${words[0]} ${words[1][0]}. ${words[2]}`;
    } else {
      return name; // If the name doesn't follow the expected format, return it as is
    }
  } else {
    const words = name?.split(/\s+/);
    if (words?.length === 3) {
      return `${words[0]} ${words[1][0]}. ${words[2]}`;
    } else {
      return name; // If the name doesn't follow the expected format, return it as is
    }
  }
};

export const getStartAndEndDateOfMonth = () => {
  // Get the current date
  const currentDate = moment();

  // Get the start date of the current month
  const startDate = currentDate.clone().startOf('month');

  // Get the end date of the current month
  const endDate = currentDate.clone().endOf('month');

  // Format the dates if needed
  const formattedStartDate = startDate.format('YYYY-MM-DD');
  const formattedEndDate = endDate.format('YYYY-MM-DD');

  // Return the start and end date in an array
  return [formattedStartDate, formattedEndDate];
};
export const generateDateArray = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  let currentDate = startDate;
  const dateArray = [];

  while (currentDate <= endDate) {
    dateArray.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'days');
  }

  return dateArray;
};

export function customEncodeURIComponent(str) {
  str = str?.replace(/\s+/g, '-');
  // Encode the string
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

export const skillsLevels = ['Beginners', 'Intermediate', 'Advanced', 'Amateurs', 'Pro', 'Open', 'ProAm', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0', '5.5+'];
export const AgeGroup = ['U10', 'U12', 'U14', 'U16', 'U18', 'Adults', 'Seniors', 'Open'];

export const Usercareerslist = [
  {
    label: 'My entries',
    value: 'general',
    icon: '/images/updated/Icon/settings.svg',
    scrollId: 'myEntries',
  },
  {
    label: 'My matches',
    value: 'participants',
    icon: '/images/updated/Icon/Athletes.svg',
    scrollId: 'myMatches',
  },
  {
    label: 'My tournaments',
    value: 'groups',
    icon: '/images/updated/Icon/team.svg',
    scrollId: 'myTournaments',
  },
  {
    label: 'My leagues',
    value: 'groups',
    icon: '/images/updated/Icon/Leagueside.svg',
    scrollId: 'myLeagues',
  },
  // {
  //   label: "Ranking history",
  //   value: "draws",
  //   icon: "/images/updated/Icon/ranking1.svg",
  // },
  // {
  //   label: "My titles",
  //   value: "livestandings",
  //   icon: "/images/updated/Icon/live.svg",
  // },
  // {
  //   label: "My teams",
  //   value: "order_of_play",
  //   icon: "/images/updated/Icon/order.svg",
  // },

  // {
  //   label: "My licences",
  //   value: "mylicense",
  //   icon: "/images/updated/Icon/booked.svg",
  // },
  // {
  //   label: "My posts",
  //   value: "search",
  //   icon: "/images/updated/Icon/Search.svg",
  // },
  // {
  //   label: "Photos",
  //   value: "photos",
  //   icon: "/images/updated/Icon/Request.svg",
  // },
  // {
  //   label: "Analytics",
  //   value: "points",
  //   icon: "/images/updated/Icon/point.svg",
  // },
  // {
  //   label: "List & Results",
  //   value: "results",
  //   icon: "/images/updated/Icon/result.svg",
  // },
];
export const StatsList = [
  {
    title: 'Tournaments',
    value: '345',
  },
  {
    title: 'Leagues took part in',
    value: 'LTSS (7)',
  },
  {
    title: 'Active licences',
    value: '4560',
  },
  {
    title: 'Titles',
    value: 'Nikita Ribakovs LAT (255)',
  },
];
export const MatchesList = [
  {
    title: 'Matches played',
    value: '10',
  },
  {
    title: 'Matches won',
    value: '8',
  },
  {
    title: 'Matches lost',
    value: '2',
  },
  {
    title: 'Games/points played',
    value: '820 (620/200)',
  },
];

export const LoginSocialIcons = [
  {
    key: 'google',
    path: '/images/updated/loginGoogle.svg',
  },
  {
    key: 'facebook',
    path: '/images/updated/loginFb.svg',
  },
  {
    key: 'twitter',
    path: '/images/updated/loginTwitter.svg',
  },
];
export const UserRolesInfo = [
  {
    title: 'Athlete',
    value: 'athlete',
    icon: '/images/updated/role4.svg',
    // icon: "/images/updated/role1.svg",
  },
  {
    title: 'Federation member',
    value: 'federationMember',
    icon: '/images/updated/role5.svg',
    // icon: "/images/updated/role2.svg",
  },
  {
    title: 'Club owner',
    value: 'clubOwner',
    icon: '/images/updated/role6.svg',
    // icon: "/images/updated/role3.svg",
  },
  {
    title: 'Club manager',
    value: 'clubManager',
    icon: '/images/updated/role1.svg',
  },
  {
    title: 'Referee',
    value: 'referee',
    icon: '/images/updated/role2.svg',
  },
  {
    title: 'Tournament organizer',
    value: 'tournamentOrganizer',
    icon: '/images/updated/role3.svg',
  },
  {
    title: 'Sport fan',
    value: 'fan',
    icon: '/images/updated/fan.svg',
  },
  {
    title: 'Other',
    value: 'other',
    icon: '/images/updated/other.svg',
  },
];
export const UserRolesSettings = ['Athlete', 'Federation member', 'Club owner', 'Club manager', 'Referee', 'Tournament organizer', 'Sport fan', 'Other'];
export const ManagerTitle = 'Manage tournaments & leagues';
export const ManagerDescription = 'Management of leagues and tournaments in the most efficient way.';
export const CustomTitle = 'Custom (White-label)';
export const CustomDescription = 'Launch your own, independent, branded web platform and mobile app powered by Tournated Engine';
export const Months = [
  { key: 'January', value: 1 },
  { key: 'February', value: 2 },
  { key: 'March', value: 3 },
  { key: 'April', value: 4 },
  { key: 'May', value: 5 },
  { key: 'June', value: 6 },
  { key: 'July', value: 7 },
  { key: 'August', value: 8 },
  { key: 'September', value: 9 },
  { key: 'October', value: 10 },
  { key: 'November', value: 11 },
  { key: 'December', value: 12 },
];

export const getYearsArray = () => {
  const endYear = 2020;
  const startYear = 2025;
  const years = [];
  for (let year = startYear; year >= endYear; year--) {
    years.push(year);
  }
  return years;
};
export const FeatureHeadTitle = [
  {
    title: '',
    description: '',
    btn: '',
    action:''
  },
  {
    title: 'Free',
    description: 'Free forever',
    btn: 'Get Started',
    action:''
  },
  {
    title: 'Basic',
    description: 'Free 39.99/month',
    btn: 'Get Started',
    action:''
  },
  {
    title: 'Custom',
    description: 'From 199€/month',
    btn: 'Get Started',
    action:''
  },
  {
    title: 'Enterprise',
    description: 'Book a call',
    btn: 'Get Started',
    action:'call'
  },
];
export const FeatureData = [
  {
    title: 'Tournaments',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: true,
  },
  {
    title: 'Entry management',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Automated groups, draws, live standings and order of play',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'List & Results (Custom metrics), Pairings (SwissSystem)',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Results management & Final standings',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Email notifications',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Partner/team search',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Practice courts / transfer bookings',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Live sreaming/scoring integrations',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Online payments',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Tournated Monetization ',
    value1: '/images/blueTick.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Leagues',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    isTitle: true,
  },
  {
    title: 'Organization/Team management',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Custom leagues',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Custom licences, tournament & ranking categories, tournaments calendar',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Third-party league integrations (IPIN, FIDE, DUPR and more)',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Rankings update in one click',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Number of team members',
    value1: '/images/dash.svg',
    value2: '25',
    value3: '50',
    value4: 'Unlimited',
    isTitle: false,
  },
  {
    title: 'White-label (custom)',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    isTitle: true,
  },
  {
    title: 'Custom landing page',
    value1: '/images/dash.svg',
    value2: '/images/dash.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Custom web platform',
    value1: '/images/dash.svg',
    value2: '/images/dash.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Custom mobile app',
    value1: '/images/dash.svg',
    value2: '/images/dash.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Personal server',
    value1: '/images/dash.svg',
    value2: '/images/dash.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'General',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    isTitle: true,
  },
  {
    title: 'Custom support',
    value1: 'General',
    value2: 'Personal',
    value3: 'Personal',
    value4: 'Personal',
    isTitle: false,
  },
  {
    title: 'Club management',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    isTitle: true,
  },
  {
    title: 'General club setup',
    value1: '/images/dash.svg',
    value2: '/images/orangeTick.svg',
    value3: '/images/orangeTick.svg',
    value4: '/images/orangeTick.svg',
    isTitle: false,
  },
  {
    title: 'Automated calendar',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Memberships',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Online bookings of courts/venues',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Online booking of classes',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Online booking of additional services',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Coach management',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Groups',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Automated scheduling',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Hourbank, wishlist',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Widget',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Number of services',
    value1: '1',
    value2: 'Unlimited',
    value3: 'Unlimited',
    value4: 'Unlimited',
    isTitle: false,
  },
  {
    title: 'Number of resources',
    value1: '4',
    value2: 'Unlimited',
    value3: 'Unlimited',
    value4: 'Unlimited',
    isTitle: false,
  },
  {
    title: 'Event management',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    isTitle: true,
  },
  {
    title: 'General event setup',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Custom packages',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Entry management',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Groups management',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Automated scheduling',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Sub-events',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'Additional services booking',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
  {
    title: 'In-app communication',
    value1: 'Сoming soon',
    value2: 'Сoming soon',
    value3: 'Сoming soon',
    value4: 'Сoming soon',
    isTitle: false,
  },
];

export const ServiceFeeHead = [
  {
    title: 'Sports',
    subTitle: '',
  },
  { title: 'Continent/Country', subTitle: '' },
  { title: 'Entry fee', subTitle: 'Tournated' },
  { title: '', subTitle: 'Yours' },
  { title: '', subTitle: 'Total (paid by athlete)' },
  { title: 'Annual licence', subTitle: 'Tournated' },
  { title: '', subTitle: 'Yours' },
  { title: '', subTitle: 'Total (paid by athlete)' },
];
export const ServiceFeeData = [
  {
    title: 'Tennis, padel, beach tennis, pickleball and other racket sports',
    countries: ['Europe', 'USA', 'Other'],
    entryFee: ['4.50%', '5.00%', '5.00%'],
    partner: ['n%', 'n%', 'n%'],
    athleteDues: ['4.50% + n%', '5.00% + n%', '5.00% + n%'],
    platformFeeAnnual: ['12.00%', '15.00%', '15.00%'],
    partnerAnnual: ['n%', 'n%', 'n%'],
    athleteDuesAnnual: ['12.00% + n%', '15.00% + n%', '15.00% + n%'],
    min: 'min 0.75€',
    minA: 'min 2.25€',
  },
  {
    title: 'Chess, sport fishing and other sports',
    countries: ['Europe', 'USA', 'Other'],
    entryFee: ['4.50%', '5.00%', '5.00%'],
    partner: ['n%', 'n%', 'n%'],
    athleteDues: ['4.50% + n%', '5.00% + n%', '5.00% + n%'],
    platformFeeAnnual: ['12.00% ', '15.00%', '15.00%'],
    partnerAnnual: ['n%', 'n%', 'n%'],
    athleteDuesAnnual: ['12.00% + n%', '15.00% + n%', '15.00% + n%'],
    min: 'min 0.75€',
    minA: 'min 2.25€',
  },
  {
    title: 'To each transaction added',
    countries: ['Payment processing fee'],
    entryFee: ['2.5% + 0.25€'],
    partner: [],
    athleteDues: [],
    platformFeeAnnual: [],
    partnerAnnual: [],
    athleteDuesAnnual: [],
  },
];
export enum LicensePaymentStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  IN_PROGRESS = 'IN_PROGRESS',
  DISPUTED = 'DISPUTED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  AUTHORIZED = 'AUTHORIZED',
  EXPIRED = 'EXPIRED',
}
export const today = new Date().toISOString();
export function parseScores(scoreString) {
  const obj = { firstArray: [], secondArray: [] };

  // Split the input string into sets
  const sets = scoreString?.split(' ');

  sets?.forEach((set) => {
    const match = set?.match(/^\[?(\d+):(\d+)\]?(?:\((\d+):(\d+)\))?$/);

    if (match) {
      const [_, mainScore1, mainScore2, tieScore1, tieScore2] = match;

      obj.firstArray.push({
        mainScore: mainScore1,
        tieScore: tieScore1 || null,
      });

      obj.secondArray.push({
        mainScore: mainScore2,
        tieScore: tieScore2 || null,
      });
    }
  });

  return obj;
}
export const DEV_URL = 'https://dev.tournated.com/';
export const STAGING_URL = 'https://staging-client.tournated.com/';
export const PROD_URL = 'https://play.tournated.com/';
export const LOCAL_URL = 'http://localhost:5256/';

export const getPlatformId = () => {
  if ([LOCAL_URL].some((link) => window.location.href.includes(link))) return 1;
  if ([STAGING_URL, DEV_URL, PROD_URL].some((link) => window.location.href.includes(link))) return 1;
  return parseInt(process.env.REACT_APP_PLATFORM_ID);
};
export const cleanScores = (scores) => {
  return scores?.map((score) => score?.replace(/[^\d()]/g, ''));
};
export const Period = {
  TWELVE_MONTHS: '12 months',
  UNTIL_END_OF_CURRENT_YEAR: 'Until end of current year',
};
// export function calculateFee(feeObj) {
//   const { fee, onsiteFee, currency } = feeObj;

//   if (fee && onsiteFee) {
//     return `${getCurrencySymbol(currency)}${Math.min(fee, onsiteFee)} - ${getCurrencySymbol(currency)}${Math.max(fee, onsiteFee)}`;
//   } else if (fee && !onsiteFee) {
//     return `${getCurrencySymbol(currency)}${fee}`;
//   } else if (onsiteFee && !fee) {
//     return `${getCurrencySymbol(currency)}${onsiteFee}`;
//   } else {
//     return '';
//   }
// }
export function calculateFee(feeObj) {
  const { fee, onsiteFee, currency } = feeObj;

  if (fee && onsiteFee) {
    if (fee === onsiteFee) {
      // If both are the same, return only one fee
      return `${getCurrencySymbol(currency)}${fee}`;
    } else {
      // If they are different, return the min-max range
      return `${getCurrencySymbol(currency)}${Math.min(fee, onsiteFee)} - ${getCurrencySymbol(currency)}${Math.max(fee, onsiteFee)}`;
    }
  } else if (fee && !onsiteFee) {
    return `${getCurrencySymbol(currency)}${fee}`;
  } else if (onsiteFee && !fee) {
    return `${getCurrencySymbol(currency)}${onsiteFee}`;
  } else {
    return '';
  }
}
export function calculateFeeNew(feeObj, additionalFee, isDoubleAmount) {
  const { fee, onsiteFee, currency } = feeObj;
  const updatedFee = isDoubleAmount ? fee * 2 + additionalFee : fee + additionalFee;
  const updatedOnsiteFee = isDoubleAmount ? onsiteFee * 2 + additionalFee : onsiteFee + additionalFee;
  if (updatedFee && updatedOnsiteFee) {
    if (updatedFee === updatedOnsiteFee) {
      // If both are the same, return only one updatedFee
      return `${getCurrencySymbol(currency)}${updatedFee}`;
    } else {
      // If they are different, return the min-max range
      return `${getCurrencySymbol(currency)}${Math.min(updatedFee, updatedOnsiteFee)} - ${getCurrencySymbol(currency)}${Math.max(updatedFee, updatedOnsiteFee)}`;
    }
  } else if (updatedFee && !updatedOnsiteFee) {
    return `${getCurrencySymbol(currency)}${updatedFee}`;
  } else if (updatedOnsiteFee && !updatedFee) {
    return `${getCurrencySymbol(currency)}${updatedOnsiteFee}`;
  } else {
    return '';
  }
}

export const eventEmitter = new EventEmitter();
export const CustomWellComeMsg = 'welcomeTextCustom';
export const WellComeMsg = 'welcomeText';
