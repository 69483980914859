import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import { getCurrencySymbol, parseToJSON } from 'src/utils/helperFunction';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import { ca } from 'date-fns/locale';
import imageCompression from 'browser-image-compression';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';
import CustomFileSelect from 'src/components/CustomComponents/CustomFileSelect';

const StepAdditionalQuestions = ({ priceUnit }) => {
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();
  const displayedQuestions = new Set();
  const additionalFees = new Set();

  const handleAnswerChange = async (categoryId, playerType, questionId, event, fieldType, userId) => {
    const updatedCategories = await Promise.all(
      registrationData?.selectedCategories?.map((categoryEntry) => {
        const updateQuestions = (questions) => {
          const questionIndex = questions?.findIndex((q) => q?.questionId === questionId && q?.user === userId);

          if (questionIndex !== -1) {
            if (fieldType === 'File') {
              const file = event.target.value;

              const options = {
                maxSizeMB: 1, // Max file size in MB
                maxWidthOrHeight: 800, // Max width or height of the image
                useWebWorker: true, // Use a web worker for better performance
              };

              if (!file) {
                questions[questionIndex].answer = null;
                questions[questionIndex].file = null;
                if (questions[questionIndex].isMandatory) {
                  questions[questionIndex].hasError = true;
                }
                return questions;
              } else {
                return imageCompression(file, options)
                  .then((compressedBlob) => {
                    const compressedFile = new File([compressedBlob], file.name, {
                      type: compressedBlob.type,
                    });
                    questions[questionIndex].answer = compressedFile;
                    questions[questionIndex].file = compressedFile;
                    questions[questionIndex].hasError = false;

                    return questions;
                  })
                  .catch((error) => {
                    toast.error('File too large. Please upload file less than 1MB');
                    console.error('Error while compressing the file:', error);
                    return questions;
                  });
              }
            } else if (fieldType === 'Select') {
              const selectedOption = questions[questionIndex].fieldOptions?.find((option) => parseInt(option.id) === parseInt(event.target.value));
              questions[questionIndex].answer = event.target.value;
              questions[questionIndex].amount = String(selectedOption?.price);
            } else {
              questions[questionIndex].answer = event.target.value;
            }

            if (questions[questionIndex].isMandatory && (questions[questionIndex].answer === null || questions[questionIndex].answer === undefined || questions[questionIndex].answer === '')) {
              questions[questionIndex].hasError = true;
            } else {
              questions[questionIndex].hasError = false;
            }
          }

          return Promise.resolve(questions);
        };

        const updatedPlayer1Questions = updateQuestions([...categoryEntry?.player1additionalQuestions]);
        const updatedPlayer2Questions = updateQuestions([...categoryEntry?.player2additionalQuestions]);

        return Promise.all([updatedPlayer1Questions, updatedPlayer2Questions]).then(([player1Questions, player2Questions]) => ({
          ...categoryEntry,
          player1additionalQuestions: player1Questions,
          player2additionalQuestions: player2Questions,
        }));
      }),
    );

    updateRegistrationData({ selectedCategories: updatedCategories });
  };
  const calculateTotalAdditionalFee = (questions) => {
    return questions?.reduce((acc, question) => {
      const questionKey = `${question.questionId}-${question.user}`;
      if (question?.fieldType === 'Select' && question?.answer !== null) {
        const price = question?.fieldOptions?.find((option) => parseInt(option.id) === parseInt(question.answer))?.price;
        if (!isNaN(parseFloat(price))) {
          if (question?.askOnlyOnce) {
            if (!additionalFees.has(questionKey)) {
              additionalFees.add(questionKey);
              return acc + parseFloat(price) || 0;
            }
          } else {
            // additionalFees.add(questionKey);
            return acc + parseFloat(price) || 0;
          }
        }
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    const updatedCategories = registrationData.selectedCategories.map((categoryEntry) => {
      const totalAdditionalFeePlayer1 = calculateTotalAdditionalFee(categoryEntry?.player1additionalQuestions || []);
      const totalAdditionalFeePlayer2 = calculateTotalAdditionalFee(categoryEntry?.player2additionalQuestions || []);
      const totalFees = totalAdditionalFeePlayer1 + totalAdditionalFeePlayer2;
      if (categoryEntry?.totalAdditionalFees !== totalFees) {
        return {
          ...categoryEntry,
          totalAdditionalFees: totalFees,
        };
      }
      return categoryEntry;
    });

    if (JSON.stringify(updatedCategories) !== JSON.stringify(registrationData.selectedCategories)) {
      updateRegistrationData({ selectedCategories: updatedCategories });
    }
  }, [registrationData.selectedCategories, updateRegistrationData]);

  return (
    <Box sx={{ height: '400px', overflowY: 'scroll' }} className="my-scroller">
      {registrationData?.selectedCategories?.map((categoryEntry) => {
        const { category, partners, player1additionalQuestions, player2additionalQuestions } = categoryEntry;
        if (!player1additionalQuestions?.length && !player2additionalQuestions?.length) {
          return null;
        }

        return (
          <Box key={category?.id} sx={{ marginBottom: '16px', padding: '12px', border: '1px solid #EFEFF0', borderRadius: '8px' }}>
            <Typography variant="font18" sx={{ lineHeight: '18px', color: '#0A2540' }}>
              {category?.category?.name}
            </Typography>
            {partners?.player1 && (
              <Box sx={{ marginTop: '8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', marginBottom: '8px' }}>
                  <CustomAvatar src={partners?.player1?.avatar} size={19} seed={partners?.player1?.name + ' ' + partners?.player1?.surname} />
                  <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '16.98px', textTransform: 'capitalize' }}>
                    {partners?.player1?.name} {partners?.player1?.surname}
                  </Typography>
                </Box>
                {player1additionalQuestions.map((question) => {
                  const questionKey = `${question.questionId}-${question.user}`;
                  if (displayedQuestions.has(questionKey) && question.askOnlyOnce) return null;
                  displayedQuestions.add(questionKey);

                  return (
                    <Box key={question.questionId} sx={{ mt: '5px' }}>
                      <Box sx={{ marginBottom: '5px' }}>
                        <Typography variant="font13" sx={{ color: '#86909F', lineHeight: '18px' }}>
                          {question?.question}
                        </Typography>
                      </Box>
                      {question.fieldType === 'Select' ? (
                        <Box sx={{ width: '100%' }}>
                          <CustomSelect
                            error={question.hasError}
                            helperText={question.hasError ? 'This question is mandatory.' : ''}
                            placeholder="Select answer"
                            matchTriggerWidth
                            options={question.fieldOptions?.map((item) => ({
                              value: item.id,
                              title: item.question,
                              rightIcon:
                                parseFloat(item?.price) || item?.price == 0 ? (
                                  <CustomChip
                                    color="#FFFF"
                                    variant="primary"
                                    size="small"
                                    sx={{
                                      background: Math.sign(item?.price) === -1 ? '#32B64F' : (theme) => theme.palette.primary.main,
                                      fontSize: '12px',
                                      borderRadius: '4px',
                                      height: '20px',
                                    }}
                                  >
                                    {`${Math.sign(item?.price) === -1 ? item?.price : '+' + item?.price}${getCurrencySymbol(priceUnit)}`}
                                  </CustomChip>
                                ) : null,
                            }))}
                            selectedValue={question.answer ?? ''}
                            onChange={(value) => handleAnswerChange(category.id, 'player1', question.questionId, { target: { value } }, 'Select', question?.user)}
                            buttonProps={{
                              sx: {
                                width: '100%',
                                fontFamily: 'Inter',
                                fontSize: '12px!important',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                height: '40px',
                              },
                            }}
                          />
                        </Box>
                      ) : question.fieldType === 'File' ? (
                        <CustomFileSelect
                          label="Upload a file"
                          preselectedFile={question.answer || null} // Prepopulate if a file was already selected
                          onFileChange={(file) => handleAnswerChange(category.id, 'player1', question.questionId, { target: { value: file } }, 'File', question?.user)}
                          accept="image/*"
                          error={question.hasError}
                        />
                      ) : (
                        <CustomFormInput
                          size="small"
                          sx={{ maxWidth: '100%', height: '40px' }}
                          fullWidth
                          name={question.questionId}
                          variant="outlined"
                          type={question.fieldType.toLowerCase()}
                          value={question.answer || ''}
                          onChange={(e) => handleAnswerChange(category.id, 'player1', question.questionId, e, question.fieldType, question?.user)}
                          error={question.hasError}
                          helperText={question.hasError ? 'This question is mandatory.' : ''}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}

            {partners?.player2 && player2additionalQuestions?.length > 0 && (
              <Box sx={{ marginTop: '16px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', marginBottom: '8px' }}>
                  <CustomAvatar src={partners?.player2?.avatar} size={19} seed={partners?.player2?.name + ' ' + partners?.player2?.surname} />
                  <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '16.98px', textTransform: 'capitalize' }}>
                    {partners?.player2?.name} {partners?.player2?.surname}
                  </Typography>
                </Box>
                {player2additionalQuestions.map((question) => {
                  const questionKey = `${question.questionId}-${question.user}`;
                  if (displayedQuestions.has(questionKey) && question.askOnlyOnce) return null;
                  displayedQuestions.add(questionKey);

                  return (
                    <Box key={question.questionId} sx={{ mt: '10px' }}>
                      <Box sx={{ marginBottom: '5px' }}>
                        <Typography variant="font13" sx={{ color: '#86909F', lineHeight: '18px' }}>
                          {question?.question}
                        </Typography>
                      </Box>
                      {question.fieldType === 'Select' ? (
                        <Box sx={{ width: '100%' }}>
                          <CustomSelect
                            error={question.hasError}
                            helperText={question.hasError ? 'This question is mandatory.' : ''}
                            placeholder="Select answer"
                            matchTriggerWidth
                            options={question.fieldOptions?.map((item) => ({
                              value: item.id,
                              title: item.question,
                              rightIcon:
                                parseFloat(item?.price) || item?.price == 0 ? (
                                  <CustomChip
                                    color="#FFFF"
                                    variant="primary"
                                    size="small"
                                    sx={{
                                      background: Math.sign(item?.price) === -1 ? '#32B64F' : (theme) => theme.palette.primary.main,
                                      fontSize: '12px',
                                      borderRadius: '4px',
                                      height: '20px',
                                    }}
                                  >
                                    {`${Math.sign(item?.price) === -1 ? item?.price : '+' + item?.price}${getCurrencySymbol(priceUnit)}`}
                                  </CustomChip>
                                ) : null,
                            }))}
                            selectedValue={question.answer ?? ''}
                            onChange={(value) => handleAnswerChange(category.id, 'player2', question.questionId, { target: { value } }, 'Select', question?.user)}
                            buttonProps={{
                              sx: {
                                fontFamily: 'Inter',
                                fontSize: '12px!important',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                width: '100%',
                                height: '40px',
                              },
                            }}
                          />
                        </Box>
                      ) : question.fieldType === 'File' ? (
                        <CustomFileSelect
                          label="Upload a file"
                          preselectedFile={question.answer || null} // Prepopulate if a file was already selected
                          onFileChange={(file) => handleAnswerChange(category.id, 'player1', question.questionId, { target: { value: file } }, 'File', question?.user)}
                          accept="image/*"
                          error={question.hasError}
                        />
                      ) : (
                        <CustomFormInput
                          size="small"
                          sx={{ maxWidth: '100%', height: '40px' }}
                          fullWidth
                          name={question.questionId}
                          variant="outlined"
                          type={question.fieldType.toLowerCase()}
                          value={question.answer || ''}
                          onChange={(e) => handleAnswerChange(category.id, 'player1', question.questionId, e, question.fieldType, question?.user)}
                          error={question.hasError}
                          helperText={question.hasError ? 'This question is mandatory.' : ''}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default StepAdditionalQuestions;
