import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import CustomButton from './CustomButton';

interface FileSelectProps {
  label?: string;
  preselectedFile?: File | null;
  onFileChange: (file: File | null) => void;
  accept?: string;
  error?: boolean;
  errorMessage?: string;
}

const CustomFileSelect: React.FC<FileSelectProps> = ({ label, preselectedFile, onFileChange, accept = 'image/*', error = false, errorMessage = 'Please select a valid file.' }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(preselectedFile || null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSelectedFile(file);
        onFileChange(file);
      }
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: false,
  });

  useEffect(() => {
    // if (preselectedFile) {
    setSelectedFile(preselectedFile);
    // }
  }, [preselectedFile]);

  const handleFileRemove = () => {
    setSelectedFile(null);
    onFileChange(null);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          border: `1px solid ${error ? 'red' : '#ccc'}`,
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10px',
          cursor: 'pointer',
          backgroundColor: isDragActive ? '#f9f9f9' : '#fff',
          '&:hover': { borderColor: (theme) => (error ? 'red' : theme?.palette?.primary?.main) },
          '&:focus-within': { borderColor: '#1976d2' },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!selectedFile ? (
          <Typography
            variant="body1"
            sx={{
              color: error ? 'red' : '#86909F',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {isDragActive ? 'Drop the file here...' : label || 'Click or drag a file to upload'}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            sx={{
              color: '#000',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
          >
            {selectedFile.name}
          </Typography>
        )}
        {selectedFile && (
          <CustomButton
            onClick={(e) => {
              e.stopPropagation();
              handleFileRemove();
            }}
            variant="primary"
            size="xs"
            sx={{
              height: '21px',
            }}
          >
            Remove
          </CustomButton>
        )}
        <CloudUpload color="action" sx={{ marginLeft: '8px', fontSize: '21px' }} />
      </Box>
      {error && (
        <Typography
          variant="caption"
          sx={{
            color: 'red',
            position: 'absolute',
            bottom: '-10px',
            right: '30px',
            background: '#fff',
            paddingInline: '4px',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default CustomFileSelect;
