import React, { ComponentProps, ReactElement, useState, useCallback, memo, useRef } from 'react';
import { Autocomplete, Box, Popper, PopperProps, Tooltip } from '@material-ui/core';
import ChevronDown from '../../icons/ChevronDown';
import ClearButton from './ClearButton';
import CustomFormInput from './CustomFormInput';

type Option = {
  text: string;
  value: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
};

type Props = {
  options: Option[];
  onChange: (event: React.ChangeEvent<{}>, value: Option | null) => void;
  selectedValue?: string | null;
  placeholder: string;
  onOpen?: () => void;
  width?: string;
  clear?: boolean;
  helperText?: string;
  error?: boolean;
  onLoadMore?: any;
  hasMore?: boolean;
} & Partial<ComponentProps<typeof Autocomplete>>;

const CustomPopper = memo((props: PopperProps) => {
  return <Popper {...props} placement="top-start" />;
});
const CustomAutocomplete = ({ options, onChange, selectedValue, placeholder, error, helperText, width, onOpen = () => {}, clear = true, onLoadMore, hasMore = false, ...props }: Props) => {
  const [open, setOpen] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const lastOptionElementRef = useCallback(
    (node) => {
      if (!hasMore || loadingMore || !onLoadMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setLoadingMore(true); // Set the loading flag
          onLoadMore().finally(() => setLoadingMore(false)); // Reset the flag after the fetch
        }
      });

      if (node) observer.current.observe(node);

      return () => observer.current?.disconnect(); // Cleanup
    },
    [onLoadMore, hasMore, loadingMore],
  );
  const handleOpen = useCallback(() => {
    setOpen(true);
    onOpen();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClear = useCallback(() => {
    onChange(null, { text: null, value: null });
  }, [onChange]);

  const selectedOption = options?.find((option) => option.value === selectedValue) || null;

  return (
    <Autocomplete
      options={options ?? []}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionLabel={(option: Option) => option?.text}
      onChange={onChange}
      value={selectedOption}
      PopperComponent={CustomPopper}
      ListboxProps={{ style: { maxHeight: '250px' } }}
      sx={{ width: width ?? 'fit-content' }}
      renderOption={(props, option: Option) => {
        const isLastOption = options.length > 0 && options[options.length - 1].value === option.value;
        return (
          <li {...props} ref={isLastOption && onLoadMore ? lastOptionElementRef : undefined}>
            <Tooltip title={option.text} placement="left-start">
              <Box
                sx={{
                  display: 'flex',
                  paddingBlock: '6px',
                  width: '100%',
                  alignItems: 'center',
                  gap: '8px',
                  whiteSpace: 'nowrap',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    height: '100%',
                    flex: '1 1 auto',
                    minWidth: '0',
                  }}
                >
                  {option.leftIcon && (
                    <Box
                      sx={{
                        minWidth: '20px',
                        minHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      {option.leftIcon}
                    </Box>
                  )}
                  <Box
                    sx={{
                      flex: '1 1 auto',
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {option.text}
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: '0 0 auto',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {option.rightIcon}
                </Box>
              </Box>
            </Tooltip>
          </li>
        );
      }}
      renderInput={(params) => (
        <CustomFormInput
          name="country"
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          variant="outlined"
          {...params}
          sx={{
            width: '100%',
            maxWidth: 'none',
            input: {
              width: '100%',
              paddingLeft: 0,
            },
            '& > div': {
              paddingRight: '10px !important',
            },
            '.MuiOutlinedInput-root': { width: '100%' },
            paddingRight: '0 !important',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Box
                onClick={() => setOpen((prev) => !prev)}
                sx={{
                  height: '100%',
                  width: 'fit-content',
                  minWidth: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  justifyContent: 'end',
                  gap: '4px',
                }}
              >
                <ChevronDown sx={{ fontSize: '14px' }} />
                {selectedValue && clear && <ClearButton onClear={handleClear} />}
              </Box>
            ),
            className: selectedValue ? 'selected' : undefined,
          }}
        />
      )}
      {...props}
    />
  );
};

export default memo(CustomAutocomplete);
