import React, { forwardRef, useState, useEffect } from 'react';
import { Box, MenuItem, Typography, InputAdornment, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import NoDataFound from '../NoDataFound';
import { useTranslation } from 'react-i18next';
import CustomFilterBox from 'src/components/CustomComponents/CustomFilterBox';
import { ReactSVG } from 'react-svg';
import toast from 'react-hot-toast';
import usePlayerSelection from './usePlayerSelection';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import Loading from 'src/components/Loading';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import useAuth from 'src/hooks/useAuth';
import CustomAccordion from 'src/components/CustomComponents/CustomAccordian';
import { Link } from 'react-router-dom';
import CustomButton from 'src/components/CustomComponents/CustomButton';

const Dropdown = forwardRef(({ players, onSelect, isSelectionDisabled, tournamentId, isSecondPlayer, partnersLoading, categoryId }: any, ref) => {
  const { registrationData } = useTournamentRegistrationContext();
  const { platformData } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [validatedPlayer, setValidatedPlayer] = useState(null);
  const debouncedSearchValue = useDebouncedTerm(searchTerm, 1500);
  const { t } = useTranslation();
  const { handlePlayerValidation, loading } = usePlayerSelection(tournamentId);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    const trimmedSearchValue = debouncedSearchValue?.trim();
    if (trimmedSearchValue) {
      (async () => {
        const player = await handlePlayerValidation(trimmedSearchValue, isSecondPlayer, categoryId);
        if (player) {
          // onSelect(validatedPlayer, isSecondPlayer);
          setValidatedPlayer(player);
        } else {
          setValidatedPlayer(null);
        }
      })();
    }
  }, [debouncedSearchValue]);
  const filteredPlayers = players?.filter(
    (player) =>
      player?.name?.toLowerCase()?.includes(debouncedSearchValue) || player?.surname?.toLowerCase().includes(debouncedSearchValue) || player?.email?.toLowerCase().includes(debouncedSearchValue),
  );
  return (
    <Box
      ref={ref}
      sx={{
        borderRadius: '4px',
        maxWidth: { xs: 'none', sm: '400px' },
        minWidth: { xs: '100%', sm: '400px' },
        height: '100%',
        minHeight: { xs: '55dvh', sm: 'auto' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ width: '100%' }}>
        {registrationData?.flow == 'myself' && (
          <>
            <CustomFilterBox
              placeholder={t('Search or enter email / tID')}
              name="search"
              fullWidth
              onChange={handleSearchChange}
              value={searchTerm}
              variant="outlined"
              size="small"
              sx={{ marginBottom: '8px', maxWidth: '100%' }}
              disabled={isSelectionDisabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    {loading ? <CircularProgress size={20} sx={{ marginTop: '4px' }} /> : <ReactSVG src="/images/search.svg" style={{ marginTop: '4px' }} />}
                  </InputAdornment>
                ),
              }}
            />
            {validatedPlayer && (
              <Box sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center', gap: '10px', width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'Center', gap: '5px' }}>
                  <CustomAvatar src={validatedPlayer.avatar} alt={`${validatedPlayer.name} ${validatedPlayer.surname}`} size={28} />
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography variant="font21" sx={{ lineHeight: '14px', color: '#0A2540', whiteSpace: 'nowrap' }}>
                      {validatedPlayer.name} {validatedPlayer.surname}
                    </Typography>
                    <Typography variant="font16" sx={{ lineHeight: '14px', color: '#0A254059', whiteSpace: 'nowrap' }}>
                      {validatedPlayer.email}
                    </Typography>
                  </Box>
                </Box>
                <CustomButton variant="primary" size="small" onClick={() => onSelect(validatedPlayer, isSecondPlayer)}>
                  {t('Add')}
                </CustomButton>
              </Box>
            )}
          </>
        )}
        <Box sx={{ maxHeight: { sm: '200px', xs: '400px' }, height: '100%', overflowY: 'auto' }}>
          {partnersLoading ? (
            <Loading height="12vh" />
          ) : filteredPlayers?.length > 0 ? (
            filteredPlayers?.map((player) => {
              return (
                <CustomTooltip
                  title={!player?.validation?.isValid ? player?.validation?.message : ''}
                  sx={{ fontSize: '10px', offset: '0' }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [15, -30],
                        },
                      },
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom', 'left', 'right'],
                        },
                      },
                      {
                        name: 'preventOverflow',
                        options: {
                          boundariesElement: 'viewport',
                        },
                      },
                    ],
                  }}
                >
                  <Box sx={{ margin: '5px 0px' }}>
                    <MenuItem key={player.id} onClick={() => onSelect(player, isSecondPlayer)} sx={{ borderRadius: '6px' }} disabled={isSelectionDisabled || !player?.validation?.isValid}>
                      {!player?.validation?.isValid && (
                        <Box onClick={(e) => e.stopPropagation()}>
                          <IconButton size="small">
                            <InfoIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <CustomAvatar src={player?.avatar} alt={`${player?.name} ${player?.surname}`} size={28} seed={player?.name + ' ' + player?.surname} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                          <Typography variant="font21" sx={{ lineHeight: '14px', color: '#0A2540', whiteSpace: 'nowrap' }}>
                            {player?.name} {player?.surname}
                          </Typography>
                          <Typography variant="font16" sx={{ lineHeight: '14px', color: '#0A254059', whiteSpace: 'nowrap' }}>
                            {player?.email}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  </Box>
                </CustomTooltip>
              );
            })
          ) : (
            <NoDataFound text={registrationData?.flow === 'coach' ? t('No athletes found') : t('No partners found')} sx={{ height: { sm: '12vh', xs: '40vh' } }} />
          )}
        </Box>
      </Box>
      <Box sx={{ width: '100%', mt: 1 }}>
        <CustomAccordion
          summaryText={
            registrationData?.flow === 'coach' ? (
              <>
                To select athletes from the list, the athlete must navigate to
                <Link to="/userSettings" style={{ color: '#FF5733', textDecoration: 'none' }}>
                  "Career"
                </Link>{' '}
                section in their settings and select you as their
              </>
            ) : (
              <>
                To select partners from the list, the person must navigate to{' '}
                <Link to="/userSettings" style={{ color: '#FF5733', textDecoration: 'none' }}>
                  "Career"
                </Link>{' '}
                section in their settings and add your email to their
              </>
            )
          }
          detailsText={
            registrationData?.flow === 'coach'
              ? `coach or parent.To select a non-partner athlete, simply add their email or tID associated with the ${platformData?.title ? platformData?.title : ''} platform.`
              : ` Partners list.To select a non-partner athlete, simply add their email or tID associated with the ${platformData?.title ? platformData?.title : ''} platform.`
          }
        />
      </Box>
    </Box>
  );
});

export default Dropdown;
