import { gql } from '@apollo/client';

const DISCIPLINES = gql`
  query disciplines($filter: ListDisciplineInput) {
    disciplines(filter: $filter) {
      id
      title
      sport {
        id
        enableDiscipline
        title
      }
      icon
      resultType
    }
  }
`;

export default DISCIPLINES;
