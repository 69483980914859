import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Grid, Typography, Divider, Tooltip, CircularProgress } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import { makeStyles } from '@material-ui/styles';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PLAYER_BY_ID, RANKINGS_BY_DUPRID, SPORTS, THIRD_PARTY_LICENSE_BY_SPORTS } from 'src/graphql/queries';
import { useRegisterContext } from 'src/contexts/RegisterContext';
import Loading from 'src/components/Loading';
import NoDataFound from 'src/components/dashboard/NoDataFound';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import InfoIcon from '@material-ui/icons/Info';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import DuprConnection from 'src/components/dashboard/tournamentRegistrations/DUPRConnect';
import CustomFilterBox from 'src/components/CustomComponents/CustomFilterBox';
const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: 'relative',
  },
  passwordToggleBtn: {
    position: 'absolute',
    top: '29px',
    right: '18px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  },
  textfield: {
    // height: "40px",
    flexShrink: 0,
    borderRadius: '8px',

    background: '#FBFBFB',

    '& .MuiOutlinedInput-root': {
      // height: "37px",
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#86909F',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  submitBtn: {
    // background: "#FF5733",
    borderRadius: '4px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  skipBtn: {
    background: '#0A25401A',
    borderRadius: '4px',
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
}));

const AddLicense: FC<any> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const [licenses, setLicenses] = useState<boolean>(false);
  const [licenseData, setLicenseData] = useState<any>([]);
  const [duprData, setDuprData] = useState<any>();

  const { registerObject, updateRegisterObject, registeration } = useRegisterContext();
  const { loading: loader, data: sports } = useQuery(SPORTS, {
    variables: {
      filter: {},
    },
  });
  const [getUser, { data: playerDetails, loading: validating }] = useLazyQuery(GET_PLAYER_BY_ID);
  const { loading, data: license } = useQuery(THIRD_PARTY_LICENSE_BY_SPORTS, {
    variables: {
      // age:"16",
      filter: { sports: registerObject?.user_sports, isRequiredInUserRegistration: true },
    },
    onCompleted: (licensesData) => {
      // @ts-ignore
      const commonArray = licensesData?.thirdPartyLicensesBySportId.filter((item1) => auth?.platformData?.thirdPartyLicenses?.some((item2) => item1.id === item2.id));
      setLicenseData(commonArray);
    },
  });

  useEffect(() => {
    if (duprData?.duprId) {
      setLicenses(true);
      updateRegisterObject({
        ...registerObject,
        duprId: duprData?.duprId,
      });
    }
  }, [duprData?.duprId]);
  const debouncedSearchValue = useDebouncedTerm(registerObject?.ipinId, 1000);
  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchValue) {
        try {
          const res = await getUser({
            variables: {
              tennisId: debouncedSearchValue,
            },
          });
          if (!res?.data?.getPlayerRankingsByTennisId?.length) {
            updateRegisterObject({
              ...registerObject,
              ...{ ipinId: '' },
            });
            toast?.error(res?.data?.getPlayerRankingsByTennisId?.error);
          } else {
            toast?.success(t('IPIN ID is correct'));
          }
        } catch (error) {
          updateRegisterObject({
            ...registerObject,
            ...{ ipinId: '' },
          });
          toast?.error(error);
        }
      }
    };

    fetchData();
  }, [debouncedSearchValue]);

  const handleChange = (e, item, sportId) => {
    setLicenses(true);
    switch (item?.id) {
      case 1:
        updateRegisterObject({
          ...registerObject,
          ...{ ipinId: e?.target?.value },
        });
        break;
      case 2:
        updateRegisterObject({
          ...registerObject,
          ...{ atpId: e?.target?.value },
        });
        break;
      // Add more cases as needed
      case 3:
        updateRegisterObject({
          ...registerObject,
          ...{ wtaId: e?.target?.value },
        });
        break;
      case 4:
        updateRegisterObject({
          ...registerObject,
          ...{ duprId: e?.target?.value },
        });
        break;
      case 5:
        updateRegisterObject({
          ...registerObject,
          ...{ fideId: e?.target?.value },
        });
        break;
      // case 6:
      //   updateRegisterObject({
      //     ...registerObject,
      //     ...{ ltsU10Id: e?.target?.value },
      //   });
      //   break;
      // case 7:
      //   updateRegisterObject({
      //     ...registerObject,
      //     ...{ atpId: e?.target?.value },
      //   });
      //   break;
      // case 8:
      //   updateRegisterObject({
      //     ...registerObject,
      //     ...{ wtaId: e?.target?.value },
      //   });
      //   break;
      // case 9:
      //   updateRegisterObject({
      //     ...registerObject,
      //     ...{ fideId: e?.target?.value },
      //   });
      //   break;
      // case 10:
      //   updateRegisterObject({
      //     ...registerObject,
      //     ...{ pdlId: e?.target?.value },
      //   });
      //   break;
      default:
    }
  };
  const handleSubmit = () => {
    props.handleClick(4);
  };
  const getValueForKey = (item) => {
    switch (item) {
      case 1:
        return registerObject?.ipinId;

      case 2:
        return registerObject?.atpId;

      case 3:
        return registerObject?.wtaId;

      case 4:
        return registerObject?.duprId;

      case 5:
        return registerObject?.fideId;

      // case 6:
      //   return registerObject?.ltsU10Id;

      // case 7:
      //   return registerObject?.atpId;

      // case 8:
      //   return registerObject?.wtaId;

      // case 9:
      //   return registerObject?.fideId;
      // case 10:
      //   return registerObject?.pdlId;
      default:
        return '';
      // Default case if no condition matches
      // Do something for default case
    }
  };
  useEffect(() => {
    if (registerObject?.ipinId || registerObject?.duprId || registerObject?.atpId || registerObject?.wtaId || registerObject?.fideId) {
      setLicenses(true);
    }
  }, []);
  useEffect(() => {
    if (licenses && registerObject?.ipinId == '' && registerObject?.duprId == '' && registerObject?.atpId == '' && registerObject?.wtaId == '' && registerObject?.fideId == '') {
      setLicenses(false);
    }
  }, [registerObject?.ipinId, registerObject?.duprId, registerObject?.atpId, registerObject?.wtaId, registerObject?.fideId]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: 'max-content',
        maxHeight: '100%',
        minHeight: '474px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          //   justifyContent: "space-between",
          gap: '20px',
          height: '100%',
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ color: '#0A2540', lineHeight: '20px' }}>
            {t('Your Licenses')}
          </Typography>
          <Typography variant="font21" sx={{ color: '#0A2540', lineHeight: '20px', opacity: '35%' }}>
            {t('Attach your licenses')}
          </Typography>
          <Box sx={{ mt: 1.5 }}>
            <Divider />
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            minHeight: '377px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            style={{
              height: '100%',
              minHeight: '327px',
              maxHeight: '327px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'scroll',
            }}
            className="my-scroller"
          >
            {loading ? (
              <Loading height="100%" />
            ) : (
              <Grid container spacing={1}>
                {licenseData?.length > 0 ? (
                  licenseData?.map((item, index) => (
                    <Grid item lg={12} md={12} xs={12}>
                      <Box
                        sx={{
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          gap: '15px',
                          alignItems: 'center',
                          borderRadius: '4px',
                          height: '70px',
                          padding: '0px 14px 0px 16px',
                          borderTop: index == 0 && '1px solid #EFEFF0',
                          borderBottom: '1px solid #EFEFF0',
                          cursor: 'pointer',
                          '&:hover': {
                            background: '#0A25400D',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '7px',
                            alignItems: 'center',
                          }}
                        >
                          <img src={item?.sports[0]?.icon} style={{ height: '28px', width: '28px' }} />{' '}
                          <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '12px' }}>
                            {item?.title}
                          </Typography>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <CustomFilterBox
                            fullWidth
                            placeholder={item.id === 4 ? t('Connect your DUPR') : t('Enter Id')}
                            name={`${item?.title}`}
                            //   onBlur={handleBlur}
                            disabled={item?.id == 4}
                            value={getValueForKey(item?.id)}
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleChange(e, item, item?.sports[0]?.id)}
                            sx={{ maxWidth: 'none', width: '100%', marginRight: '5px' }}
                            InputProps={{
                              endAdornment: item?.id === 1 && validating ? <CircularProgress size={20} /> : null,
                            }}
                          />{' '}
                          {item.id === 4 && !registerObject?.duprId && (
                            <Box sx={{ marginRight: '5px', width: '30%' }}>
                              <DuprConnection duprData={duprData} setDuprData={setDuprData} isResponsive={true} />
                            </Box>
                          )}
                          {/* {item?.id == 4 && (
                            <CustomTooltip title="DUPR can be connected after signup.You need to go to settings and connect it there in third party licences">
                              <InfoIcon
                                style={{
                                  verticalAlign: 'start',
                                  color: '#86909F',
                                  cursor: 'pointer',
                                  fontSize: '15.5px',
                                  right: 1,
                                  position: 'absolute',
                                }}
                              />
                            </CustomTooltip>
                          )} */}
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <NoDataFound text={t('No license found')} sx={{ height: '30vh' }} />
                )}
              </Grid>
            )}
          </Box>
          <Box sx={{ mt: 1 }}>
            {licenses ? (
              <CustomButton size="medium" type="submit" variant="primary" onClick={() => handleSubmit()} sx={{ width: '100%', mt: '8px' }}>
                {t('Continue')}
              </CustomButton>
            ) : (
              <CustomButton size="large" type="submit" variant="secondary" onClick={() => handleSubmit()} sx={{ width: '100%', mt: '8px' }}>
                {t('Skip')}
              </CustomButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddLicense;
