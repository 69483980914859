import { gql } from '@apollo/client';
const ALLGROUPMATCHES = gql`
  query allGroupMatches($filter: ListGroupInput, $page: Int, $limit: Int, $name: String) {
    allGroupMatches(page: $page, limit: $limit, filter: $filter, name: $name) {
      groups {
        id
        name
        segment
        matches {
          isDelete
          score {
            round
            score
            roundFormat
            entry {
              id
            }
          }
          id
          court {
            name
            id
          }
          date
          time
          round
          winner {
            id
          }
          winnerPartner {
            id
          }
          status
          matchStatus
          scoreType
          entry1 {
            id
            registrationRequest {
              id
              seed
              segment
              assignedSegment
              additionalSegment
              team {
                id
                title
                image
              }
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
          entry1partner {
            id
            registrationRequest {
              id
              seed
              segment
              assignedSegment
              additionalSegment
              team {
                id
                title
                image
              }
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
          entry2 {
            id
            registrationRequest {
              id
              seed
              segment
              assignedSegment
              additionalSegment
              team {
                id
                title
                image
              }
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
          entry2partner {
            id
            registrationRequest {
              id
              seed
              segment
              assignedSegment
              additionalSegment
              team {
                id
                title
                image
              }
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default ALLGROUPMATCHES;
