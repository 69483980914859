import { gql } from '@apollo/client';

const PLATFORM_BY_ID = gql`
  query organizationPlatformByFields($platformId: Int!) {
    organizationPlatformByFields(platformId: $platformId) {
      platform {
        id
        title
      }
    }
  }
`;

export default PLATFORM_BY_ID;
