import { gql } from '@apollo/client';
const GET_RANKINGS = gql`
  query tournamentRankings($filter: ListTournamentRankingInput!) {
    tournamentRankings(filter: $filter) {
      tournamentRankings {
        id
        name
        tournament {
          id
          title
        }
        tournamentCategory {
          id
          category {
            name
            id
            identifyTeamMember
          }
        }
        # segment
        rankings {
          id
          place
          points
          tournamentCategory {
            id
            category {
              name
              id
            }
          }
          entry {
            id
            # registrationRequest {
            id
            team {
              id
              title
              image
            }
            users {
              id
              user {
                id
                email
                name
                surname
                avatar
                nation
              }
            }
          }
          # }
        }
      }
      total
    }
  }
`;
export default GET_RANKINGS;
