import { FC, useState } from 'react';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router';
import GetFlag from 'src/utils/getFlags';
import { getFideTitle, parseToJSON } from 'src/utils/helperFunction';
import { useTranslation } from 'react-i18next';
import { DUPRBox, getColorByRating } from './Participants';
import { Tournament } from 'src/types';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import PeopleIcon from '@material-ui/icons/People';
import TeamMemberDetails from './TeamMembersDetails';

const useStyles = makeStyles((theme) => ({
  name: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  country: {
    color: '#1B3861',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineheight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
}));

interface participentsTableProps {
  participants?: any;
  selectedValue?: any;
  categories?: any;
  tournament?: Tournament;
}

const ParticipantsResponsive: FC<participentsTableProps> = ({ participants, selectedValue, categories, tournament }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isTeam = participants?.some((entry) => entry?.team);
  const [openModal, setOpenModal] = useState(false);
  const [entryData, setEntryData] = useState(null);
  const displayDuprskilllevel = tournament?.isDuprRatingEnabled;
  const displaySettings = tournament?.league?.league?.sports;
  const shouldDisplayDupr = tournament?.isDuprRatingEnabled;
  const shouldDisplayRank = categories?.find((category) => category?.category?.id == selectedValue)?.category?.category?.isDisplayRank;

  function getColorByName(name) {
    const upperCaseName = name?.toUpperCase();
    const colorMap = {
      MD: '#00A100',
      Q: '#1F6BFF',
      QWC: '#FF5733',
      MDWC: '#FFC600',
      LL: '#8C2135',
      QW: '#FF5790',
    };
    return colorMap[upperCaseName] || '#CCCCCC';
  }

  const foundCategory = categories?.find((category) => category?.category?.id == selectedValue);

  const timeCont = parseToJSON(foundCategory?.category?.category?.timeControl);

  const getRatings = (user) => {
    switch (timeCont?.title?.toLowerCase()?.trim()) {
      case 'blitz':
        return user?.blitzElo === 'Notrated' ? '0' : user?.blitzElo ?? '0';
      case 'rapid':
        return user?.rapidElo === 'Notrated' ? '0' : user?.rapidElo ?? '0';
      case 'standard':
        return user?.standardElo === 'Notrated' ? '0' : user?.standardElo ?? '0';
      default:
        return '0';
    }
  };

  return (
    <Box>
      {participants?.map((entry, index) =>
        isTeam ? (
          <>
            <Box
              sx={{
                //   mt: index % 2 === 1 ? 1 : 0,
                //   mb: index % 2 === 1 ? 1 : 0,
                padding: '10px 0px 10px 0px',
                display: 'flex',
                flexDirection: 'column',
                borderTop: index % 2 === 1 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                borderBottom: index % 2 === 1 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                background: index % 2 === 1 ? '#FBFBFB' : '#FFFF',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <CustomButton
                  size="auto"
                  variant="text"
                  sx={{ gap: '5px' }}
                  onClick={() => {
                    setOpenModal(true), setEntryData(entry?.users);
                  }}
                >
                  <img src={entry?.team?.image} style={{ height: '25px', width: '25px', borderRadius: '6px' }} />
                  <Typography sx={{ color: '#0A2540', lineHeight: '24px' }} variant="font18">
                    {entry?.team?.title || ''}
                  </Typography>
                  <CustomChip size="xs" variant="secondary" square sx={{ maxHeight: '20px', maxWidth: '20px', minHeight: '20px', minWidth: '20px' }}>
                    <PeopleIcon sx={{ fontSize: '16px' }} />
                  </CustomChip>
                </CustomButton>
                {(entry?.assignedSegment || entry?.additionalSegment) && (
                  <Box sx={{ padding: '0px 10px 0px 10px' }}>
                    <Typography
                      sx={{
                        width: 'max-content',
                        fontWeight: 500,
                        border: `1px solid ${getColorByName(entry?.assignedSegment)}`,
                        padding: '4px 12px',
                        borderRadius: '4px',
                        fontSize: { lg: '12px', xs: '10px' },
                        fontFamily: 'Inter',
                        color: getColorByName(entry?.assignedSegment) === '#FFC600' ? '#0A2540' : getColorByName(entry?.assignedSegment) === '#CCCCCC' ? '#0A2540' : '#0A2540',
                      }}
                    >
                      {entry?.assignedSegment === 'Q' && entry?.additionalSegment ? 'QW' : entry?.assignedSegment}
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* 2 */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '0 10px 0 35px',
                  alignItems: 'center',
                }}
              >
                {foundCategory?.category?.category?.isDisplayCity && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    {entry?.users[0]?.user?.city && <img src="/images/updated/worldwide.svg" style={{ width: '14px', height: '14px' }} />}
                    <Typography className={classes.country}>{entry?.users[0]?.user?.city}</Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                  {tournament?.league?.league?.sports?.resourceTitle === 'Round' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                        {t('Rating')}:{' '}
                      </Typography>
                      <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                        {getRatings(entry?.users[0])}
                      </Typography>
                    </Box>
                  )}
                  {tournament?.league?.league?.sports?.resourceTitle !== 'Round' && shouldDisplayRank && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                        {t('Rank')}:
                      </Typography>
                      <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                        #{entry?.users[0]?.rank ?? 0}
                      </Typography>
                    </Box>
                  )}
                  {displaySettings?.isDisplaySeeds &&
                    (entry?.seed ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                          {t('Seed')}:{' '}
                        </Typography>
                        <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                          {entry?.seed}
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    ))}
                </Box>
              </Box>
            </Box>
          </>
        ) : entry?.type == 'doubles' ? (
          <Box
            sx={{
              padding: '10px 0px 10px 0px',
              display: 'flex',
              flexDirection: 'column',
              borderBottom: index % 2 === 1 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
              borderTop: index % 2 === 1 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
              background: index % 2 === 1 ? '#FBFBFB' : '#FFFF',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                gap: '10px',
                padding: '0 20px',
              }}
            >
              <Typography sx={{ color: '#0A2540', lineHeight: '24px' }} variant="font18">
                {index + 1}.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {entry?.users.map((user) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      padding: '0 10px',
                      width: '100%',
                      mb: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '3px',
                        width: '100%',
                      }}
                    >
                      {' '}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: '10px',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '87%',
                          }}
                        >
                          <GetFlag country={user?.user?.citizenship ? user?.user?.citizenship : user?.user?.nation} style={{ width: '15px', height: '10px' }} />
                          <CustomAvatar
                            src={user?.user?.avatar}
                            size={23}
                            key={user?.user?.id}
                            alt={user?.user?.name}
                            seed={user?.user?.name + ' ' + user?.user?.surname}
                            onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              flex: '1 1 auto',
                              minWidth: 0,
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                <Typography
                                  className={classes.name}
                                  sx={{
                                    cursor: 'pointer',
                                    wordWrap: 'break-word',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '100%',
                                    flex: '1 1 auto',
                                    minWidth: 0,
                                    whiteSpace: 'nowrap',
                                  }}
                                  onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                                >
                                  {`${user?.user?.name?.charAt(0).toUpperCase()}. ${user?.user?.surname}`}
                                </Typography>
                                {displayDuprskilllevel && user?.duprRating && <DUPRBox rating={user.duprRating} color={getColorByRating(user.duprRating)} />}
                              </Box>
                              {foundCategory?.category?.category?.isDisplayClub && user?.user?.user_club_id?.clubName && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                  <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />{' '}
                                  <Tooltip title={user?.user?.user_club_id?.clubName}>
                                    <Typography className={classes.country} sx={{ overflow: 'hidden', maxWidth: { sm: '100%', xs: '100px' }, textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                      {user?.user?.user_club_id?.clubName}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              )}
                            </Box>
                            {displaySettings?.isDisplayAthleteChessTitle && user?.fideTitle && (
                              <Box
                                sx={{
                                  padding: '0px 5px',
                                  background: getFideTitle(user?.fideTitle)?.color,
                                  color: '#FFFF',
                                  borderRadius: '5px',
                                  //   marginRight: "10px",
                                  width: 'max-content',
                                }}
                              >
                                <Typography variant="font21" sx={{ lineHeight: '14.52px', color: '#FFFF' }}>
                                  {getFideTitle(user?.fideTitle)?.abbreviation}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        {shouldDisplayRank && (
                          <Box>
                            <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                              #{user?.rank || 0}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {foundCategory?.category?.category?.isDisplayCity && (
                        <Box sx={{ marginBottom: '3px', marginRight: '20px' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '2px',
                            }}
                          >
                            {entry?.users[0]?.user?.city && <img src="/images/updated/worldwide.svg" style={{ width: '14px', height: '14px' }} />}
                            <Typography className={classes.country}>{entry?.users[0]?.user?.city}</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {/* 2 */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '5px 10px 0 35px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: '20px',
                  width: '100%',
                  borderTop: '1px solid #EDF1F6',
                  paddingTop: '5px',
                }}
              >
                {foundCategory?.category?.category?.type === 'doubles' && shouldDisplayDupr && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Tooltip title={t('Total DUPR')}>
                      <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px', display: 'flex' }}>
                        {t('T.DUPR')}:
                      </Typography>
                    </Tooltip>
                    <Typography className={classes.country}>{(entry?.users?.[0]?.duprRating + entry?.users?.[1]?.duprRating)?.toFixed(3)}</Typography>
                  </Box>
                )}
                {foundCategory?.category?.category?.type === 'doubles' && shouldDisplayRank && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                      {t('Total')}:
                    </Typography>
                    <Typography className={classes.country}>#{entry?.users?.[0]?.rank + entry?.users?.[1]?.rank}</Typography>
                  </Box>
                )}

                {displaySettings?.isDisplaySeeds && entry?.seed ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                      {t('Seed')}:{' '}
                    </Typography>
                    <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                      {entry?.seed}
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
                {(entry?.assignedSegment || entry?.additionalSegment) && (
                  <Box sx={{ padding: '0px 10px 0px 10px' }}>
                    <Typography
                      sx={{
                        width: 'max-content',
                        fontWeight: 500,
                        border: `1px solid ${getColorByName(entry?.assignedSegment)}`,
                        padding: '4px 12px',
                        borderRadius: '4px',
                        fontSize: { lg: '10px', xs: '8px' },
                        fontFamily: 'Inter',
                        color: getColorByName(entry?.assignedSegment) === '#FFC600' ? '#0A2540' : getColorByName(entry?.assignedSegment) === '#CCCCCC' ? '#0A2540' : '#0A2540',
                      }}
                    >
                      {entry?.assignedSegment === 'Q' && entry?.additionalSegment ? 'QW' : entry?.assignedSegment}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              //   mt: index % 2 === 1 ? 1 : 0,
              //   mb: index % 2 === 1 ? 1 : 0,
              padding: '10px 0px 10px 0px',
              display: 'flex',
              flexDirection: 'column',
              borderTop: index % 2 === 1 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
              borderBottom: index % 2 === 1 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
              background: index % 2 === 1 ? '#FBFBFB' : '#FFFF',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              {entry?.users.map((user) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '0 10px',
                    width: '100%',
                  }}
                >
                  <Typography sx={{ color: '#0A2540', lineHeight: '24px' }} variant="font18">
                    {index + 1}.
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      width: '87%',
                    }}
                  >
                    <GetFlag country={user?.user?.citizenship ? user?.user?.citizenship : user?.user?.nation} style={{ width: '15px', height: '10px' }} />
                    <CustomAvatar
                      src={user?.user?.avatar}
                      size={23}
                      key={user?.user?.id}
                      alt={user?.user?.name}
                      seed={user?.user?.name + ' ' + user?.user?.surname}
                      onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flex: '1 1 auto',
                        minWidth: 0,
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                          <Tooltip title={`${user?.user?.name}-${user?.user?.surname}`}>
                            <Typography
                              className={classes.name}
                              sx={{
                                cursor: 'pointer',
                                wordWrap: 'break-word',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                flex: '1 1 auto',
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                              }}
                              onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
                            >
                              {`${user?.user?.name?.charAt(0).toUpperCase()}. ${user?.user?.surname}`}
                            </Typography>
                          </Tooltip>
                          {displayDuprskilllevel && user?.duprRating && <DUPRBox rating={user.duprRating} color={getColorByRating(user.duprRating)} />}
                        </Box>
                        {foundCategory?.category?.category?.isDisplayClub && user?.user?.user_club_id?.clubName && (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                            <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} /> <Typography className={classes.country}>{user?.user?.user_club_id?.clubName}</Typography>
                          </Box>
                        )}
                      </Box>
                      {displaySettings?.isDisplayAthleteChessTitle && user?.fideTitle && (
                        <Box
                          sx={{
                            padding: '0px 5px',
                            background: getFideTitle(user?.fideTitle)?.color,
                            color: '#FFFF',
                            borderRadius: '5px',
                            //   marginRight: "10px",
                            width: 'max-content',
                          }}
                        >
                          <Typography variant="font21" sx={{ lineHeight: '14.52px', color: '#FFFF' }}>
                            {getFideTitle(user?.fideTitle)?.abbreviation}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
              {(entry?.assignedSegment || entry?.additionalSegment) && (
                <Box sx={{ padding: '0px 10px 0px 10px' }}>
                  <Typography
                    sx={{
                      width: 'max-content',
                      fontWeight: 500,
                      border: `1px solid ${getColorByName(entry?.assignedSegment)}`,
                      padding: '4px 12px',
                      borderRadius: '4px',
                      fontSize: { lg: '12px', xs: '10px' },
                      fontFamily: 'Inter',
                      color: getColorByName(entry?.assignedSegment) === '#FFC600' ? '#0A2540' : getColorByName(entry?.assignedSegment) === '#CCCCCC' ? '#0A2540' : '#0A2540',
                    }}
                  >
                    {entry?.assignedSegment === 'Q' && entry?.additionalSegment ? 'QW' : entry?.assignedSegment}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* 2 */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 10px 0 35px',
                alignItems: 'center',
              }}
            >
              {foundCategory?.category?.category?.isDisplayCity && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                  {entry?.users[0]?.user?.city && <img src="/images/updated/worldwide.svg" style={{ width: '14px', height: '14px' }} />}
                  <Typography className={classes.country}>{entry?.users[0]?.user?.city}</Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                {tournament?.league?.league?.sports?.resourceTitle === 'Round' && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                      {t('Rating')}:{' '}
                    </Typography>
                    <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                      {getRatings(entry?.users[0])}
                    </Typography>
                  </Box>
                )}
                {tournament?.league?.league?.sports?.resourceTitle !== 'Round' && shouldDisplayRank && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                      {t('Rank')}:
                    </Typography>
                    <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                      #{entry?.users[0]?.rank ?? 0}
                    </Typography>
                  </Box>
                )}
                {displaySettings?.isDisplaySeeds &&
                  (entry?.seed ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Typography variant="font21" sx={{ color: '#00000059', lineHeight: '14.52px' }}>
                        {t('Seed')}:{' '}
                      </Typography>
                      <Typography variant="font21" sx={{ lineHeight: '20px', color: '#0A2540BF' }}>
                        {entry?.seed}
                      </Typography>
                    </Box>
                  ) : (
                    ''
                  ))}
              </Box>
            </Box>
          </Box>
        ),
      )}
      <TeamMemberDetails openModal={openModal} setOpenModal={setOpenModal} entryData={entryData} />
    </Box>
  );
};

export default ParticipantsResponsive;
