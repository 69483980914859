import toast from "react-hot-toast";

// eslint-disable-next-line consistent-return
const getAbsoluteUrl = (url) => {
  const urlRegex = /^(https?:\/\/)?([\w.]+)+\.\w{2,}(\/\S*)?$/i;

  if (!urlRegex.test(url)) {
    toast.error('Invalid Url')
    return null; // Return null for invalid URLs
  }
  const isAbsoluteUrl = /^https?:\/\//i.test(url);

  const baseUrl = isAbsoluteUrl ? "" : "https://"; // Replace with your actual production domain

  // Construct the absolute URL using the base URL
  const absoluteUrl = baseUrl + url;
  return absoluteUrl;
};

export default getAbsoluteUrl;
