import React, { useEffect, useMemo, useState, useCallback, Dispatch, SetStateAction, ComponentProps } from 'react';
import { Box, Link, Stack, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import chroma from 'chroma-js';
import { HideImage, SettingsOutlined } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';

import LicenceModal from 'src/components/widgets/modals/LicenceModal';
import CustomButton from '../../CustomComponents/CustomButton';
import CustomHeader from '../../CustomComponents/CustomHeader';
import SportIcon from '../../CustomComponents/SportIcon';
import GetFlag from '../../../utils/getFlags';
import { ColorExtractor } from 'react-color-extractor';
import useAuth from 'src/hooks/useAuth';
import CustomChip from '../../CustomComponents/CustomChip';
import CustomImagePopup from '../../CustomComponents/CustomImagePopup';
import CustomTabs from '../../CustomComponents/CustomTabs';
import prettifyDates from '../../../utils/prettifyDates';
import { Tournament } from 'src/types';
import { useTournament } from './TournamentPage';
import CustomPaperCard from '../../CustomComponents/CustomPaperCard';
import { ME } from 'src/graphql/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';

const DEFAULT_PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR;

type Props = {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  tabsConfig?: ComponentProps<typeof CustomTabs>;
};

const TournamentHeader = ({ tabsConfig, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { user, dispatch, setLoginModalOpen, setTournamentRegistrationModalOpen } = useAuth();
  const [timeLeft, setTimeLeft] = useState<string>();
  const [formattedStartDate, setFormattedStartDate] = useState<string>();
  const isCompact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { tournament, id, refetch, loading } = useTournament();
  const [getUser, { data, error }] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: data.getProfile,
        },
      });
    },
    onError: () => {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    },
  });
  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'In %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: '1 minute',
        mm: '%d minutes',
        h: '1 hour',
        hh: '%d hours',
        d: '1 day',
        dd: '%d days',
        M: '1 month',
        MM: '%d months',
        y: '1 year',
        yy: '%d years',
      },
    });

    const updateTimer = () => {
      if (tournament?.startDate) {
        const duration = moment.duration(tournament?.entryDeadline.diff(tournament?.localTime()));
        const totalDays = duration.asDays();

        const timeUnits = [
          { value: totalDays, label: 'd' },
          { value: duration.hours(), label: 'h' },
          { value: duration.minutes(), label: 'm' },
          { value: duration.seconds(), label: 's' },
        ];

        const timeLeftParts = timeUnits.map(({ value, label }) => `${String(Math.max(0, Math.floor(value))).padStart(2, '0')}${label}`);
        setTimeLeft(duration.asSeconds() <= 0 ? undefined : timeLeftParts.join(' '));
        setFormattedStartDate(prettifyDates(tournament?.startDate, tournament?.endDate, tournament?.timeZone.abbreviation));
      }
    };

    updateTimer();
    const timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, [tournament?.startDate]);

  const isRegistrationClosed = tournament?.isRegistrationClosed || tournament?.closeRegistration || tournament?.tournamentCategory?.every((category) => category?.status?.toLowerCase() === 'closed');

  const isOrganizer = useMemo(() => {
    const userId = parseInt(user?.id);
    if (!userId) return false;

    const { organizer, additionalReferee, referee } = tournament ?? {};
    const isSuperAdmin = user?.role?.toLowerCase() === 'superadmin';
    const isOrganizer = organizer?.member?.id === userId;
    const isReferee = referee?.referee?.id === userId;
    const isAdditionalReferee = additionalReferee?.some((ref) => ref?.referee?.id === userId);

    return isOrganizer || isReferee || isAdditionalReferee || isSuperAdmin;
  }, [user?.id, user?.role, tournament]);

  const handleRegisterClick = useCallback(async () => {
    try {
      const getUserProfile = await getUser();
      if (getUserProfile?.data) {
        if (tournament?.registerLink) {
          window.open(tournament?.registerLink);
        } else {
          setTournamentRegistrationModalOpen(true);
        }
      } else {
        // toast?.error('Session expired.Please login again');
        setLoginModalOpen(true);
      }
    } catch (error) {
      console.log('error', error);
      toast?.error('Session expired.Please login again');
    }
  }, [tournament]);

  const avatar = tournament?.poster;
  const [imgColors, setImgColors] = useState<string[]>();
  const dominantColor = imgColors?.[0] ?? DEFAULT_PRIMARY_COLOR;
  const token = Cookies?.get('token');

  const renderStatusChip = () => {
    const status = tournament?.isFinished ? 'finished' : tournament?.isOngoing ? 'live' : 'closed';

    const config = {
      closed: { color: '#FF5A5A', textKey: 'registrationClosed' },
      live: { color: '#5ce65c', textKey: 'In progress' },
      finished: { color: '#d3d3d3', textKey: tournament?.endDate.from(tournament?.localTime()) },
    }[status];

    return (
      <CustomChip
        size="xs"
        variant="secondary"
        sx={{
          pointerEvents: 'none',
          backgroundColor: chroma(config.color).alpha(0.2).hex(),
          borderColor: config.color,
          color: config.color,
          '& > svg *': {
            fill: config.color,
          },
          '.icon': {
            backgroundColor: config.color,
          },
        }}
      >
        {t(config.textKey)}
      </CustomChip>
    );
  };

  return (
    <>
      <CustomHeader tabsConfig={tabsConfig} color={dominantColor} banner={tournament?.coverPhoto}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            overflow: 'hidden',
            flexDirection: isCompact ? 'column' : 'row',
            gap: '28px',
            alignItems: isCompact ? 'center' : undefined,
          }}
        >
          <Box sx={{ display: 'flex', gap: { xs: '12px', md: '24px' }, width: '100%' }}>
            <CustomPaperCard
              skeleton={loading}
              sx={{ border: loading ? undefined : `1px solid ${dominantColor}`, flexShrink: 0, height: { xs: '120px', md: '220px' }, aspectRatio: '1/1.414', borderRadius: '8px', overflow: 'hidden' }}
            >
              {avatar ? (
                <CustomImagePopup src={avatar}>
                  <ColorExtractor getColors={setImgColors}>
                    <img alt={`${tournament?.title ?? 'Tournament'} poster`} style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={avatar} />
                  </ColorExtractor>
                </CustomImagePopup>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', background: chroma(dominantColor).alpha(0.2).hex() }}>
                  <Stack gap="5px" alignItems="center">
                    <HideImage sx={{ fill: chroma(dominantColor).alpha(0.8).hex() }} />
                    <Typography variant="font21" lineHeight="100%" color={chroma(dominantColor).alpha(0.8).hex()} sx={{ textAlign: 'center' }}>
                      Poster missing
                    </Typography>
                  </Stack>
                </Box>
              )}
            </CustomPaperCard>
            <Stack sx={{ flex: 1, height: '100%', justifyContent: { xs: 'end', md: 'space-between' }, overflow: 'hidden', maxWidth: '360px', paddingBlock: { xs: '6px', md: '0' } }}>
              {!isCompact && <Box></Box>}
              <Stack gap="6px" sx={{ position: 'relative' }}>
                {loading ? (
                  <CustomPaperCard skeleton sx={{ height: '16px', width: '80px', marginBottom: '4px' }} />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                      marginLeft: '-2px',
                      marginBottom: '4px',
                      borderBottom: `1px solid ${dominantColor}`,
                      paddingBottom: '6px',
                      paddingRight: '24px',
                      maskImage: 'linear-gradient(to right, black calc(100% - 24px), transparent)',
                      width: 'fit-content',
                    }}
                  >
                    <SportIcon sport={tournament?.league.league.sports.title} color="currentcolor" />
                    <Typography
                      component={Link}
                      href={`/league/${tournament?.league.league.id}`}
                      variant="font22"
                      color="primary"
                      underline="none"
                      sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'currentcolor' }}
                    >
                      {tournament?.league.league.title}
                    </Typography>
                  </Box>
                )}
                {loading ? (
                  <CustomPaperCard skeleton sx={{ height: '16px', width: '120px' }} />
                ) : (
                  <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <GetFlag country={tournament?.country} style={{ height: '10px' }} />
                    <Typography
                      variant="font18"
                      sx={{
                        lineHeight: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {tournament?.country}, {tournament?.city}
                    </Typography>
                  </Box>
                )}
                {loading ? (
                  <CustomPaperCard skeleton sx={{ height: '24px', width: '180px' }} />
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: { xs: 2, sm: 3 },
                      lineClamp: { xs: 2, sm: 3 },
                      overflow: 'hidden',
                      lineHeight: '110%',
                    }}
                  >
                    {tournament?.title}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Box>
          <Stack sx={{ height: '100%', justifyContent: 'space-between', flex: 1, width: '100%' }}>
            <Box></Box>
            <Stack sx={{ alignItems: { xs: 'start', md: 'end' }, gap: '8px', width: '100%' }}>
              <Stack sx={{ gap: '8px', alignItems: 'end' }}>
                {loading ? (
                  <CustomPaperCard skeleton sx={{ height: '20px', width: '100px' }} />
                ) : (
                  <>
                    {isRegistrationClosed ? (
                      <Typography variant="font18" sx={{ lineHeight: '100%', whiteSpace: 'nowrap' }}>
                        {t('registrationClosed')}
                      </Typography>
                    ) : timeLeft ? (
                      <Typography variant="font18" sx={{ lineHeight: '100%', whiteSpace: 'nowrap' }}>
                        Time left to join:{' '}
                        <Typography variant="font23" component="span">
                          {timeLeft}
                        </Typography>
                      </Typography>
                    ) : (
                      renderStatusChip()
                    )}
                  </>
                )}
              </Stack>
              <Box sx={{ display: 'flex', gap: '12px', width: isCompact ? '100%' : 'fit-content' }}>
                {loading ? (
                  <CustomPaperCard skeleton sx={{ height: '48px', width: '200px' }} />
                ) : (
                  <CustomButton disabled={isRegistrationClosed} onClick={handleRegisterClick} size="large" variant="primary" sx={{ width: '200px', flex: 1 }}>
                    {isRegistrationClosed ? t('registrationClosed') : !user ? 'Log in to join' : t('joinTournament')}
                  </CustomButton>
                )}
                {isOrganizer && (
                  <CustomButton size="large" variant="outline" square onClick={() => window.open(`${process.env.REACT_APP_FE_URL}/dashboard/all-tournaments/new?tournament=${id}&token=${token}`)}>
                    <SettingsOutlined sx={{ fontSize: '20px' }} />
                  </CustomButton>
                )}
              </Box>
              {loading ? (
                <CustomPaperCard skeleton sx={{ height: '20px', width: '160px' }} />
              ) : (
                <Typography variant="font21" sx={{ textAlign: 'end', whiteSpace: 'nowrap' }}>
                  {formattedStartDate}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      </CustomHeader>
      <LicenceModal open={open} setOpen={setOpen} />
    </>
  );
};

export default TournamentHeader;
