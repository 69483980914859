import { gql } from '@apollo/client';

const CREATE_TEAM = gql`
  mutation createTeam($title: String, $country: String, $status: String, $sport: Int, $club: Int, $members: [JSONObject!], $discipline: Int, $image: Upload, $platform: Int) {
    createTeam(createTeamInput: { title: $title, country: $country, status: $status, sport: $sport, club: $club, members: $members, discipline: $discipline, image: $image, platform: $platform }) {
      id
      title
      status
      country
    }
  }
`;

export default CREATE_TEAM;
