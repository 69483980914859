import React, { forwardRef, useState } from 'react';
import { Box, MenuItem, Typography, Checkbox, FormControlLabel, makeStyles, IconButton } from '@material-ui/core';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import NoDataFound from '../NoDataFound';
import Loading from 'src/components/Loading';
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip';
import CustomAutocomplete from 'src/components/CustomComponents/CustomAutocomplete';
import { useQuery } from '@apollo/client';
import { GET_VALID_TEAM_MEMBERS } from 'src/graphql/queries';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { useNavigate } from 'react-router';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    position: 'relative',
    paddingRight: '10px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: '4px',
    },
  },
  categoryName: {
    lineHeight: '18px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  categoryFee: {
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  perAthlete: {
    fontSize: '10px',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '12.1px',
    color: '#00000059',
    textTransform: 'lowercase',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  infoIcon: {
    // marginLeft: theme.spacing(1),
  },
}));
const TeamsDropDown = forwardRef(({ Teams, onSelect, categoryId, isSelectionDisabled, partnersLoading, category, handleClose, setIsModalOpen, tournamentId }: any, ref) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedMembers, setSelectedMembers] = useState<Record<number, boolean>>({});
  const [selectedTeam, setSelectedTeam] = useState<any>();
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();
  const selectedCategory = registrationData?.selectedCategories?.find((item) => item?.category?.category?.id === categoryId);
  const { data: teamsData, loading } = useQuery(GET_VALID_TEAM_MEMBERS, {
    skip: !selectedCategory?.team?.id,
    // fetchPolicy: 'network-only',
    variables: {
      id: parseInt(selectedCategory?.team?.id),
      categoryId: parseInt(category),
      tournamentId: parseInt(tournamentId),
    },
  });
  // const handleCheckboxChange = (member: any, checked: boolean) => {
  //   // setSelectedMembers((prev) => ({ ...prev, [member?.member?.id]: checked }));
  //   // if (onSelect) {
  //   onSelect(member?.member, checked);
  //   // }
  // };
  const handleTeamChange = (value) => {
    setSelectedTeam(value ?? '');
    const selectedTeam = Teams?.find((item) => item?.id == value);
    updateRegistrationData({
      selectedCategories: registrationData?.selectedCategories?.map((categoryEntry) =>
        categoryEntry?.category?.category?.id === categoryId
          ? {
              ...categoryEntry,
              team: selectedTeam,
              members: [],
            }
          : categoryEntry,
      ),
    });
  };

  return (
    <Box
      ref={ref}
      sx={{
        borderRadius: '4px',
        maxWidth: { xs: 'none', sm: '400px' },
        minWidth: { xs: '100%', sm: '400px' },
        height: '100%',
        minHeight: { xs: '55dvh', sm: 'auto' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ maxHeight: { sm: '300px', xs: '400px' }, height: '100%', overflowY: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mb: 2 }}>
            {/* <CustomAutocomplete
              width="100%"
              options={
                Teams?.map((team) => ({
                  text: team?.title,
                  value: team?.id,
                })) ?? []
              }
              onChange={(event: React.ChangeEvent<{}>, { value }) => {
                handleTeamChange(value ?? '');
              }}
              selectedValue={(selectedCategory?.team?.id || (selectedTeam as string)) ?? null}
              placeholder={'Teams'}
              // sx={{ mb: 2 }}
            /> */}
            {/* <CustomButton
              variant="primary"
              size="medium"
              onClick={() => {
                setIsModalOpen(false);
              }}
              sx={{ width: '100%', maxWidth: '120px' }}
            >
              Add
            </CustomButton> */}
          </Box>
          <Box className={`${classes.scrollContainer}`}>
            {partnersLoading ? (
              <Loading height="12vh" />
            ) : Teams?.length > 0 ? (
              Teams?.map((team) => {
                const isSelected = !!registrationData?.selectedCategories?.find((entry) => entry.team?.id == team?.id);

                return (
                  <CustomButton
                    size="small"
                    variant="secondary"
                    sx={{
                      width: '100%',
                      justifyContent: 'start',
                      height: '50px',
                      whiteSpace: 'normal',
                      textAlign: 'start',
                      mb: 1,
                      background: isSelected ? '#FF57330D' : '#ffff',
                      color: '#d5d5d5',
                    }}
                    onClick={() => {
                      handleTeamChange(team?.id ?? ''), setIsModalOpen(false);
                    }}
                  >
                    <Checkbox size="small" color="primary" checked={isSelected} onChange={() => handleTeamChange(team?.id ?? '')} />

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <img src={team?.image} style={{ height: '25px', width: '25px', borderRadius: '6px' }} />
                      <Typography>{team?.title || ''}</Typography>
                    </Box>
                  </CustomButton>
                );
              })
            ) : (
              <NoDataFound text="No teams found" sx={{ height: { sm: '12vh', xs: '40vh' } }} />
            )}
          </Box>
          {/* {loading ? (
            <Loading height="12vh" />
          ) : teamsData?.validTeamMembers?.length > 0 ? (
            teamsData?.validTeamMembers?.map((team) => (
              <Box key={team.id} sx={{ borderRadius: '6px' }}>
                <CustomTooltip key={team?.member?.id} title={!team.validation?.isValid ? team.validation?.message : ''} disableHoverListener={team?.validation?.isValid} arrow>
                  <Box sx={{ opacity: team?.validation?.isValid ? 1 : 0.4 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!team?.validation?.isValid}
                          checked={!!selectedCategory?.members?.some((mem) => mem?.id == team?.member?.id)}
                          onChange={(e) => handleCheckboxChange(team, e.target.checked)}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          <CustomAvatar src={team.avatar} alt={`${team?.member?.name} ${team?.member?.surname}`} size={28} seed={team?.member?.name + ' ' + team?.member?.surname} />
                          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography variant="font21" sx={{ lineHeight: '14px', color: '#0A2540', whiteSpace: 'nowrap' }}>
                              {team?.member?.name} {team?.member?.surname}
                            </Typography>
                            <Typography variant="font16" sx={{ lineHeight: '14px', color: '#0A254059', whiteSpace: 'nowrap' }}>
                              {team?.member?.email}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </CustomTooltip>
              </Box>
            ))
          ) : (
            <NoDataFound text="No members found" sx={{ height: { sm: '12vh', xs: '40vh' } }} />
          )} */}
          <CustomButton
            variant="primary"
            size="medium"
            onClick={() => {
              handleClose();
              navigate('/create-teams');
            }}
            sx={{ width: '100%', mt: 2 }}
          >
            Create new team +
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
});

export default TeamsDropDown;
